import qs from 'querystring';
/**
 * 通用的工具集
 */

// 判断是否为微信浏览器
export function isWeixinBrowser() {
    // 在浏览器的场景下
    return typeof navigator !== 'undefined' &&
        typeof navigator.userAgent !== 'undefined'
        ? /micromessenger/.test(navigator.userAgent.toLowerCase())
        : false;
}

// 判断是否为iOS
export function isIOS() {
    return typeof navigator !== 'undefined' &&
        typeof navigator.userAgent !== 'undefined'
        ? /iPhone|iPad|iTouch/i.test(navigator.userAgent)
        : false;
}

/**
 * 获得from_page参数
 * params {String} page_session_id
 */
export function getFromPageParam(pageSessionID) {
    const schema = 'taoumaimai://page?';
    let path = '';
    let search = '';
    if (typeof document !== 'undefined') {
        const referrer = document.referrer;
        if (referrer) {
            path = referrer.split('?')[0];
            search = referrer.replace(path, '');
        }
    }

    return (
        schema +
        qs.stringify({
            mm_page_name: decodeURIComponent(path),
            mm_page_session_id: pageSessionID,
            mm_page_type: 'h5',
        }) +
        '&' +
        search.substr(1)
    );
}
