import React, { useState, useEffect, CSSProperties, useContext } from 'react';
import {
    Icon,
    Statistic,
    Spin,
    message,
    Button,
    Modal,
    Alert,
    Popover,
} from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { defaultAxios, useNet, isNetSuccess } from '@/utils';
import { StoreContext } from '@/stores';
import { AntTable } from '@/components/antd';
import SwitchButton from '@/components/common/switch-button';
import CUserModal from '@/components/common/mai-trend/cuser-modal';
import ActiveTable from './active-table';
import JobTable from './job-table';
import CompeteJobTable from './compete-job-table';
import voyagerTrack from '@/utils/voyager_track';
import { getValueOrDefault } from '@/utils/helpers';
import { BlockLoading2 } from '../block-loading';
import { tryNewCall } from '@/containers/hoc/call-center';
import { CtxProvider } from '@/utils/context';
import { CUser2Contacts } from '@/components/common/mai-trend/cuser-modal/cuser2contacts';
import Ccpopover from '@/components/common/cc-popover';

import './index.scss';

export interface IKeyBehaviorProps {
    cid?: number | string; // 客户id
    maiTrendData: any;
    isPrivateCus: boolean; // 是否是私池客户
    defaultCustomerName: string; // 客户的名称
    customerId: string | number; // 客户站内id
}

const statisticValueStyle: CSSProperties = {
    fontSize: '16px',
    marginBottom: '5px',
    fontWeight: 500,
};

const statisticCanClickValueStyle: CSSProperties = {
    ...statisticValueStyle,
    color: '#0052ff',
    cursor: 'pointer',
};

const PeriodMap = new Map([
    [3, 'threeday'],
    [7, 'oneweek'],
    [30, 'onemonth'],
]);

const KeyBehavior: React.FC<IKeyBehaviorProps> = props => {
    const {
        cid,
        maiTrendData,
        isPrivateCus,
        defaultCustomerName,
        customerId,
    } = props;
    console.log('KeyBehavior', props);
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const cUserStore = store.getDefaultCUserStore();
    const {
        remainCount,
        decodeCUser,
        checkCUserDecoded,
        getRemainDecodeNumber,
        setMaiTrendContactTrigger,
        maiTrendContactTrigger,
        setMaiTrendContactCurrentData,
        maiTrendContactCurrentData,
    } = cUserStore;

    const [cUserModalVis, setCUserModalVis] = useState(false);
    const [cUserMode, setCUserMode] = useState(false);
    const [canFetchCUser, setCanFetchCUser] = useState(false);
    const [cUserQueryScene, setCUserQueryScene] = useState<
        | 'REGISTER_HR_3D'
        | 'REGISTER_HR_7D'
        | 'REGISTER_HR_30D'
        | 'ACTIVE_HR_3D'
        | 'ACTIVE_HR_7D'
        | 'ACTIVE_HR_30D'
        | 'HR_COUNT'
        | 'REGISTRATION_3D'
        | 'REGISTRATION_7D'
        | 'REGISTRATION_30D'
        | 'ALL_MEMBERS'
        | ''
    >('');

    const [period, setPeriod] = useState(30);
    const [modalVisible, setModalVisable] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<any>();
    const [innerJobDetailLoading, setInnerJobDetailLoading] = useState(false);
    const [innerJobDetailVis, setInnerJobDetailVis] = useState(false);
    const [innerJobDetailType, setInnerJobDetailType] = useState('');
    const [innerJobData, setInnerJobData] = useState([]);
    const [popoverVisable, setPopoverVisable] = useState(false);
    const [title, SetTitle] = useState('');

    const loadInnerJobDetail = async (
        type: string,
        isHighSalary?: boolean,
        isAllJobs?: boolean,
        days?: number,
    ) => {
        const api = '/crm/customer/mai/maimai/jobdetails';
        const params = {
            cid: customerId,
            queryScene: isAllJobs
                ? 'ALL_JOBS'
                : isHighSalary
                ? `HIGH_JOBS_${days}D`
                : `JOBS_${days}D`,
        };

        await getRemainDecodeNumber();
        setInnerJobDetailLoading(true);
        setInnerJobDetailType(type);
        setInnerJobDetailVis(true);
        const [d, e] = await defaultAxios.put(api, params);
        setInnerJobDetailLoading(false);
        if (isNetSuccess(d, e) && _.isArray(d?.data)) {
            setInnerJobData(d?.data);
        }
    };

    // 获取（获取c端用户）的权限
    const fetchCUserAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:customer';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const allCUserAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:all:get-c-user';
            });
            const mineCUserAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:mine:get-c-user';
            });
            if (isPrivateCus) {
                setCanFetchCUser(!!mineCUserAuth?.visible);
            } else {
                setCanFetchCUser(!!allCUserAuth?.visible);
            }
        }
    };
    const onVisibleChange = (visable: any) => {
        console.log('vvvvvvisable', visable);
        setPopoverVisable(visable);
    };
    useEffect(() => {
        fetchCUserAuth();
        getRemainDecodeNumber();
        return () => {
            setMaiTrendContactCurrentData(null);
            setMaiTrendContactTrigger(0);
        };
    }, []);

    const decodePhoneAndT2Contract = async (id: number, oData: any) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id, true);
            if (data) {
                // 拉起转联系人
                const transData = {
                    uid: id,
                    username: oData.hrName,
                    position: oData.hrPosition,
                    realname: oData.hrName,
                    company: defaultCustomerName,
                    call: true,
                    phone: data,
                    loginTime: '',
                    profileUrl: '',
                };
                cUserStore.setMaiTrendContactTrigger(
                    cUserStore.maiTrendContactTrigger + 1,
                );
                cUserStore.setMaiTrendContactCurrentData({
                    ...transData,
                    decodedPhone: data,
                });
            }
        } else {
            if (cUserStore.remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次解密手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次转联系人，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>
                                {cUserStore.remainCount}
                            </span>
                            次，请确认是否转联系人？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id, true);
                    if (data) {
                        // 拉起外呼
                        getRemainDecodeNumber();
                        // 拉起转联系人
                        const transData = {
                            uid: id,
                            username: oData.hrName,
                            position: oData.hrPosition,
                            realname: oData.hrName,
                            company: defaultCustomerName,
                            call: true,
                            phone: data,
                            loginTime: '',
                            profileUrl: '',
                        };
                        cUserStore.setMaiTrendContactTrigger(
                            cUserStore.maiTrendContactTrigger + 1,
                        );
                        cUserStore.setMaiTrendContactCurrentData({
                            ...transData,
                            decodedPhone: data,
                        });
                    }
                },
            });
        }
    };

    const decodePhoneHandle = async (
        id: string,
        name: string,
        position: string,
    ) => {
        const [d, e] = await checkCUserDecoded(id);

        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id);
            if (data) {
                // 拉起外呼
                tryNewCall(data, true, {
                    showFollowLog: true,
                    customerName: defaultCustomerName,
                    customerId: cid,
                    userName: name,
                    userPosition: position,
                });
            }
        } else {
            if (remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>{remainCount}</span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id);
                    if (data) {
                        // 拉起外呼
                        tryNewCall(data, true, {
                            customerName: defaultCustomerName,
                            showFollowLog: true,
                            customerId: cid,
                            userName: name,
                            userPosition: position,
                        });
                    }
                },
            });
        }
    };

    const innerJobDetailColumns = [
        {
            title: '职位',
            width: '120px',
            dataIndex: 'jobPosition',
            fixed: true,
            render: (text: any, record: any) => {
                return (
                    <a href={record.jobUrl} target="_blank">
                        {text}
                    </a>
                );
            },
        },
        {
            title: '薪酬范围',
            key: 'salary',
            width: '160px',
            render(v: any, item: any) {
                item = item || {};
                const { jobMaxSalary, jobMinSalary } = item;
                if (!jobMaxSalary && !jobMinSalary) {
                    return '--';
                }
                return `${`${Math.round(Number(jobMinSalary) / 1000)}k` ||
                    '--'} ~ ${`${Math.round(Number(jobMaxSalary) / 1000)}k` ||
                    '--'}`;
            },
        },
        {
            title: '工作地址',
            width: '100px',
            dataIndex: 'jobAddress',
            render: (text: any) => getValueOrDefault(text),
        },
        {
            title: '发布时间',
            width: '120px',
            dataIndex: 'pubDate',
            render: (text: any) => {
                return text ? moment(text).format('YYYY-MM-DD') : '-';
            },
            sorter: (a: any, b: any) => a.pubDate - b.pubDate,
        },
        {
            title: '发布人',
            width: '100px',
            dataIndex: 'hrName',
            render: (text: any, record: any) => {
                return (
                    <span>
                        <a href={record.hrProfileUrl} target="_blank">
                            {text}
                        </a>{' '}
                        {isPrivateCus ? (
                            <Popover
                                getPopupContainer={() => {
                                    return document.body as HTMLElement;
                                }}
                                style={{ zIndex: 666666 }}
                                onVisibleChange={onVisibleChange}
                                placement="right"
                                content={
                                    <Ccpopover
                                        uid={
                                            popoverVisable
                                                ? record.hrUid
                                                : undefined
                                        }
                                        whetherShow={false}
                                    />
                                }
                            >
                                <Icon
                                    onClick={() => {
                                        decodePhoneHandle(
                                            record.hrUid,
                                            record.hrName,
                                            record.hrPosition,
                                        );
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    type="phone"
                                    theme="twoTone"
                                    twoToneColor="#0052ff"
                                />
                            </Popover>
                        ) : null}
                    </span>
                );
            },
        },
        {
            title: '发布人职位',
            width: '120px',
            dataIndex: 'hrPosition',
        },
        {
            title: '发布人最后活跃时间',
            dataIndex: 'lastActiveDate',
            width: 160,
            render: (text: any) => {
                return text ? moment(text).format('YYYY-MM-DD') : '-';
            },
            sorter: (a: any, b: any) => a.lastActiveDate - b.lastActiveDate,
        },
        {
            title: '职位曝光数',
            dataIndex: 'jobShowCnt',
            width: 80,
            render: (text: any) => getValueOrDefault(text),
        },
        {
            title: '职位访问数',
            dataIndex: 'jobVisitorCnt',
            width: 80,
            render: (text: any) => getValueOrDefault(text),
        },
        {
            title: '投递简历数',
            dataIndex: 'jobResumeCnt',
            width: 80,
            render: (text: any) => getValueOrDefault(text),
        },
        {
            title: '通过初筛数',
            width: 80,
            dataIndex: 'passScreeningCnt',
            render: (text: any) => getValueOrDefault(text),
        },
        {
            title: '操作',
            width: '60px',
            render: (item: any) => {
                return (
                    <span
                        onClick={() => {
                            decodePhoneAndT2Contract(item.hrUid, item);
                        }}
                        style={{ color: '#0052ff', cursor: 'pointer' }}
                    >
                        转联系人
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        console.log('关键行为');
        voyagerTrack('crm_customer_keyactivity', {
            userId: authStore.userInfo.sysUser.id,
            userName: authStore.userInfo.userName,
        });
    }, []);

    const getDateRange = (days: number, offset = 1) => {
        const endDate = moment()
            .subtract(offset, 'days')
            .format('YYYY-MM-DD');
        const startDate = moment()
            .subtract(days, 'days')
            .format('YYYY-MM-DD');

        return startDate + ' ~ ' + endDate; // 输出时间区间
    };
    const handleCancel = (e: any) => {
        console.log(e);
        setModalVisable(false);
    };
    return (
        <>
            <CtxProvider
                ctx={{
                    position: 'drawer',
                    positionDetail: 'customer-drawer-maitrend-cuser',
                    data: { cid: maiTrendData?.id },
                }}
            >
                <CUser2Contacts
                    trigger={maiTrendContactTrigger}
                    customerInfo={maiTrendData}
                    cuser={maiTrendContactCurrentData}
                />
            </CtxProvider>
            <Modal
                title={`${innerJobDetailType}-${defaultCustomerName}`}
                visible={innerJobDetailVis}
                okText={'关闭'}
                width={1234}
                cancelButtonProps={{ style: { visibility: 'hidden' } }}
                onCancel={() => setInnerJobDetailVis(false)}
                onOk={() => setInnerJobDetailVis(false)}
            >
                <>
                    {/* {JSON.stringify(jobDetailData)} */}
                    {innerJobDetailLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <>
                            <Alert
                                showIcon
                                style={{
                                    fontSize: '12px',
                                    marginBottom: '8px',
                                }}
                                type="warning"
                                message={
                                    <div>
                                        <p style={{ marginBottom: '0px' }}>
                                            手机号需加密，无法明文展示。但是您每天有200次外呼机会，目前您的剩余可用机会为{' '}
                                            <span style={{ color: 'red' }}>
                                                {remainCount}
                                            </span>{' '}
                                            次。（注意：同一用户一天之内外呼多次，只消耗1次机会）
                                        </p>
                                    </div>
                                }
                            />
                            <AntTable
                                scroll={{ x: 700, y: 350 }}
                                pagination={false}
                                dataSource={innerJobData}
                                columns={innerJobDetailColumns}
                            ></AntTable>
                        </>
                    )}
                </>
            </Modal>
            <CUserModal
                modalVis={cUserModalVis}
                setModalVis={setCUserModalVis}
                defaultCustomerName={defaultCustomerName}
                customerOriginData={maiTrendData}
                userName={authStore.userInfo.userName || ''}
                userId={authStore.userInfo.sysUser.id}
                showMode={cUserMode}
                isPrivateCus={isPrivateCus}
                cid={customerId}
                crm_cid={cid}
                queryScene={cUserQueryScene}
            />
            <div className="key-behavior">
                <div className="key-behavior-inner">
                    <p className="key-behavior-inner-title">
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            <SwitchButton
                                buttonOptions={[
                                    { value: 3, name: '3天' },
                                    { value: 7, name: '7天' },
                                    { value: 30, name: '30天' },
                                ]}
                                value={period}
                                onChange={val => {
                                    console.log(val);
                                    setPeriod(val);
                                }}
                            />
                            <span className="key-behavior-inner-title-tips">
                                {`数据统计时间：${getDateRange(period)}`}
                            </span>
                        </div>

                        {canFetchCUser && (
                            <Button
                                type={'primary'}
                                size={'small'}
                                onClick={() => {
                                    setCUserMode(false);
                                    setCUserModalVis(true);
                                    setCUserQueryScene('');
                                }}
                            >
                                获取c端用户信息
                            </Button>
                        )}
                    </p>
                    <div className="key-behavior-inner-body">
                        <div className="key-behavior-inner-row">
                            <div className="key-behavior-inner-item">
                                <div className="key-behavior-inner-info">
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_job_cnt`
                                                ]
                                            ) {
                                                loadInnerJobDetail(
                                                    `近${Number(period)}天发布`,
                                                    false,
                                                    false,
                                                    Number(period),
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_job_cnt`
                                                ]
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_job_cnt`
                                                ] || '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            {`近${period}天发布`}
                                        </span>
                                    </div>

                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_register_hr_cnt`
                                                ]
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene(
                                                    `REGISTER_HR_${period}D` as typeof cUserQueryScene,
                                                );
                                                setCUserModalVis(true);
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene: `REGISTER_HR_${period}D`,
                                                    },
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_register_hr_cnt`
                                                ]
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_register_hr_cnt`
                                                ] || '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            {`近${period}天注册`}
                                        </p>
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_active_hr_cnt`
                                                ]
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene(
                                                    `ACTIVE_HR_${period}D` as typeof cUserQueryScene,
                                                );
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene: `ACTIVE_HR_${period}D`,
                                                    },
                                                );
                                                setCUserModalVis(true);
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_active_hr_cnt`
                                                ]
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_active_hr_cnt`
                                                ] || '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            {`近${period}天活跃`}
                                        </span>
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_recruit_person_member_cnt`
                                                ]
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene(
                                                    `REGISTRATION_${period}D` as typeof cUserQueryScene,
                                                );
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene: `REGISTRATION_${period}D`,
                                                    },
                                                );
                                                setCUserModalVis(true);
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_recruit_person_member_cnt`
                                                ]
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.[
                                                    `maimai_${PeriodMap.get(
                                                        period,
                                                    )}_recruit_person_member_cnt`
                                                ] || '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            {`近${period}天新开会员`}
                                        </span>
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_effective_job_cnt2
                                            ) {
                                                loadInnerJobDetail(
                                                    '总职位',
                                                    undefined,
                                                    true,
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_effective_job_cnt2
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.maimai_effective_job_cnt2 ||
                                                '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            总有效职位
                                        </span>
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_register_hr_cnt
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene('HR_COUNT');
                                                setCUserModalVis(true);
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_register_hr_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.maimai_register_hr_cnt ||
                                                '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            总注册人数
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="key-behavior-table-content">
                    <p className="key-behavior-table-title">
                        <span>招聘身份活跃</span>
                    </p>
                    <button
                        className="check-more"
                        onClick={() => {
                            setModalVisable(true);
                            SetTitle('招聘身份活跃');
                            setModalContent(
                                <ActiveTable
                                    company={defaultCustomerName}
                                    queryScene={`ACTIVE_HR_${period}D`}
                                    isPrivateCus={isPrivateCus}
                                    crm_cid={cid}
                                    customerOriginData={maiTrendData}
                                />,
                            );
                        }}
                    >
                        查看更多
                    </button>
                    <div className="key-behavior-table-body">
                        <ActiveTable
                            company={defaultCustomerName}
                            queryScene={`ACTIVE_HR_${period}D`}
                            isPrivateCus={isPrivateCus}
                            crm_cid={cid}
                            customerOriginData={maiTrendData}
                        />
                    </div>
                </div>
                <div className="key-behavior-table-content">
                    <p className="key-behavior-table-title">
                        <span>职位发布</span>
                    </p>
                    <button
                        className="check-more"
                        onClick={() => {
                            setModalVisable(true);
                            SetTitle('职位发布');
                            setModalContent(
                                <JobTable
                                    company={defaultCustomerName}
                                    queryScene={`JOBS_${period}D`}
                                    isPrivateCus={isPrivateCus}
                                    cid={customerId}
                                    customerOriginData={maiTrendData}
                                />,
                            );
                        }}
                    >
                        查看更多
                    </button>
                    <div className="key-behavior-table-body">
                        <JobTable
                            company={defaultCustomerName}
                            queryScene={`JOBS_${period}D`}
                            isPrivateCus={isPrivateCus}
                            cid={customerId}
                            customerOriginData={maiTrendData}
                        />
                    </div>
                </div>
                <div className="key-behavior-table-content">
                    <p className="key-behavior-table-title">
                        <span>竞品职位发布</span>
                    </p>
                    <button
                        className="check-more"
                        onClick={() => {
                            setModalVisable(true);
                            SetTitle('竞品职位发布');
                            setModalContent(
                                <CompeteJobTable
                                    customerName={defaultCustomerName}
                                    lastDay={period}
                                />,
                            );
                        }}
                    >
                        查看更多
                    </button>
                    <div className="key-behavior-table-body">
                        <CompeteJobTable
                            customerName={defaultCustomerName}
                            lastDay={period}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title={`${title}-${defaultCustomerName}`}
                width={1234}
                maskClosable={false}
                cancelButtonProps={{
                    hidden: true,
                }}
                okButtonProps={{
                    hidden: true,
                }}
                destroyOnClose
                className="key-behavior-modal"
                visible={modalVisible}
                onCancel={handleCancel}
            >
                {modalContent}
            </Modal>
        </>
    );
};
export default observer(KeyBehavior);
