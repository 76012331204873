import React, { useState } from 'react';
import { Divider, Spin, Icon } from 'antd';
import { AntTable } from '@/components/antd';
import { defaultAxios } from '@/utils';
import { useRequest } from 'ahooks';
import { getValueOrDefault, convertToUnicode } from '@/utils/helpers';
import voyagerTrack from '@/utils/voyager_track';
import Selector from '../selector';

import './index.less';

interface TeleprompterProps {
    style?: React.CSSProperties;
    phoneNum?: string | null;
    customerId?: string | number;
}

interface SalesAIParams {
    customerId?: string | number;
    phone?: number | string;
    scriptGroup: (number | string | undefined)[];
}

const fetchThumbsUp = async (scriptId: number, thumbsUpType: number) => {
    const [d, e] = await defaultAxios.post(`/crm/thumbsUp/insertScript`, {
        scriptId,
        thumbsUpType,
    });
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

const fetchSalesAI = async ({
    customerId,
    phone,
    scriptGroup,
}: SalesAIParams) => {
    const [d, e] = await defaultAxios.post(`/crm/admin/salesAI`, {
        customerId,
        phone,
        scriptGroup,
    });
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

const Teleprompter: React.FC<TeleprompterProps> = ({
    style,
    phoneNum,
    customerId,
}) => {
    const [selectedTag, setSelectedTag] = useState<string | number>();
    const [selectedTableTag, setSelectedTableTag] = useState<
        (string | number)[]
    >([]);

    const [thumbsUpType, setThumbsUpType] = useState(0);

    const { data: salesData, loading } = useRequest(
        () =>
            fetchSalesAI({
                customerId,
                phone: phoneNum!,
                scriptGroup: [selectedTag].filter(Boolean),
            }),
        {
            ready: !!phoneNum,
            refreshDeps: [selectedTag],
        },
    );

    return (
        <div className="teleprompter-container" style={style}>
            <div className="teleprompter-callee">
                <span>{getValueOrDefault(salesData?.customerName)}</span>
                <Divider type="vertical" />
                <span>{getValueOrDefault(salesData?.contactsName)}</span>
                <Divider type="vertical" />
                <span>{getValueOrDefault(salesData?.contactsDuty)}</span>
            </div>
            <div className="teleprompter-content">
                <div className="teleprompter-category-title">智能话术</div>
                <div className="teleprompter-category-group">
                    <div className="teleprompter-category-item">
                        <span className="teleprompter-category-label">
                            需求及效果：
                        </span>
                        <Selector
                            value={selectedTag}
                            options={salesData?.labels?.tagsDemand || []}
                            onChange={selected => {
                                setSelectedTag(selected);
                                voyagerTrack('crm_sales_ai_filter', {
                                    id: selected,
                                    customerId,
                                });
                            }}
                        />
                    </div>
                    <div className="teleprompter-category-item">
                        <span className="teleprompter-category-label">
                            预算及渠道：
                        </span>
                        <Selector
                            value={selectedTag}
                            options={salesData?.labels?.tagsBudget || []}
                            onChange={selected => {
                                setSelectedTag(selected);
                                voyagerTrack('crm_sales_ai_filter', {
                                    id: selected,
                                    customerId,
                                });
                            }}
                        />
                    </div>
                    <div className="teleprompter-category-item">
                        <span className="teleprompter-category-label">
                            KP及其他：
                        </span>
                        <Selector
                            value={selectedTag}
                            options={salesData?.labels?.tagsKP || []}
                            onChange={selected => {
                                setSelectedTag(selected);
                                voyagerTrack('crm_sales_ai_filter', {
                                    id: selected,
                                    customerId,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                <div className="teleprompter-content">
                    <div className="teleprompter-card">
                        <div className="teleprompter-card-title">
                            <span className="teleprompter-card-title-main">
                                {salesData?.optimizeDto?.label}
                            </span>
                            <span className="teleprompter-card-title-sub">
                                {salesData?.optimizeDto?.thought}
                            </span>
                        </div>
                        <div className="teleprompter-card-content">
                            <div className="teleprompter-card-prompt">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: getValueOrDefault(
                                            salesData?.optimizeDto?.content,
                                        ),
                                    }}
                                />
                                {salesData?.optimizeDto?.content ? (
                                    <div className="teleprompter-thumbsUp">
                                        <Icon
                                            type="dislike"
                                            style={{
                                                marginRight: 10,
                                                cursor: 'pointer',
                                            }}
                                            theme={
                                                thumbsUpType === 2
                                                    ? 'twoTone'
                                                    : undefined
                                            }
                                            onClick={async () => {
                                                await fetchThumbsUp(
                                                    salesData?.optimizeDto.id,
                                                    2,
                                                );
                                                setThumbsUpType(2);
                                            }}
                                        />
                                        <Icon
                                            type="like"
                                            style={{ cursor: 'pointer' }}
                                            theme={
                                                thumbsUpType === 1
                                                    ? 'twoTone'
                                                    : undefined
                                            }
                                            onClick={async () => {
                                                await fetchThumbsUp(
                                                    salesData?.optimizeDto.id,
                                                    1,
                                                );
                                                setThumbsUpType(1);
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className="teleprompter-tags">
                                {
                                    <Selector
                                        value={selectedTableTag}
                                        options={
                                            Object.keys(
                                                salesData?.scriptDynamicData ||
                                                    {},
                                            ).map(key => ({
                                                value: key,
                                                label: key,
                                            })) || []
                                        }
                                        onChange={selected => {
                                            setSelectedTableTag(prev => {
                                                return prev.includes(selected)
                                                    ? [...prev].filter(
                                                          item =>
                                                              item !== selected,
                                                      )
                                                    : [...prev, selected];
                                            });

                                            voyagerTrack(
                                                'crm_sales_ai_table_tag_filter',
                                                {
                                                    id: convertToUnicode(
                                                        selected + '',
                                                    ),
                                                    customerId,
                                                },
                                            );
                                        }}
                                    />
                                }
                            </div>
                            {Object.keys(salesData?.scriptDynamicData || {})
                                ?.filter(item =>
                                    selectedTableTag.includes(item),
                                )
                                .map(key => (
                                    <div
                                        className="teleprompter-table-wrap"
                                        key={key}
                                    >
                                        <div className="teleprompter-table-title">
                                            {key}
                                        </div>
                                        <AntTable
                                            columns={salesData?.scriptDynamicData[
                                                key
                                            ].columns.map((col: any) => ({
                                                ...col,
                                                dataIndex: col.key,
                                                width: 100,
                                            }))}
                                            dataSource={
                                                salesData?.scriptDynamicData[
                                                    key
                                                ].dataSource
                                            }
                                            pagination={false}
                                            scroll={{
                                                y: 180,
                                                x: true,
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="teleprompter-others">
                        {salesData?.otherContent?.map((item: any) => (
                            <div
                                className="teleprompter-others-item"
                                onClick={() => {
                                    setSelectedTag(item.labelValue);
                                    voyagerTrack('crm_sales_ai_other_filter', {
                                        id: item.labelValue,
                                        customerId,
                                    });
                                }}
                            >
                                <div className="teleprompter-others-item-title">
                                    {item.label}
                                </div>
                                <div
                                    className="teleprompter-others-item-msg"
                                    dangerouslySetInnerHTML={{
                                        __html: item.content,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Teleprompter;
