import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DTable, isMultipleCell, makeObjColClickable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContactListStore } from '../store';
import { runInAction } from 'mobx';
import DefaultDrawer from '../drawer';
import { StoreContext } from '@/stores';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { tryRefresh, useQueryAsObject } from '@/utils';
import { useLeadDrawerSnippets } from '@/components/common/lead-drawer/hooks';
import { fetchUserProfile } from '../../../../../utils/helpers';
import voyagerTrack from '@/utils/voyager_track';

const DemoTable: React.FC<{
    defaultStore: ContactListStore;
    loading: boolean;
    type?: string;
}> = ({ defaultStore, loading, type }) => {
    const withInfoKey = 'contacts-list-' + (type || 'default');
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const { defaultMSelect } = defaultStore;
    const eventCode = 'PUBLIC-VIEW';

    const { defaultEE } = defaultStore;
    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const [drawerVis, setDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);
    const [cid, setCid] = useState(undefined);

    const [drawerVisCus, setDrawerVisCus] = useState(false);
    const [cidCus, setCidCus] = useState(undefined);

    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: drawerVisCus,
        setDrawerVis: setDrawerVisCus,
        cid: cidCus,
    });

    const [leadDrawerLid, setLeadDrawerLid] = useState(undefined);
    const [leadDrawerVis, setLeadDrawerVis] = useState(false);
    const [____, cusLeadSnippet] = useLeadDrawerSnippets({
        drawerVis: leadDrawerVis,
        setDrawerVis: setLeadDrawerVis,
        itemId: leadDrawerLid,
    });

    useQueryAsObject(queryObj => {
        if (queryObj.id) {
            setDetailId(queryObj.id);
            setDrawerVis(true);
        }
    });

    const dataTable = (
        <>
            {cusDrawerSnippet}
            {cusLeadSnippet}
            <DefaultDrawer
                contactsListStore={defaultStore}
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                detailId={detailId}
                destroyOnClose={true}
                cid={cid}
                onEdit={data => {
                    runInAction(() => {
                        defaultStore.setAction('update', {
                            id: data.id,
                        });
                    });
                }}
                onCreat={() => {
                    runInAction(() => {
                        defaultStore.setAction('create');
                        defaultStore.startNewData();
                    });
                }}
            />
            <DTable
                onColumnsUpdated={() => tryRefresh(['contacts'])}
                sceneCode={'crm_contacts'}
                eventCode={eventCode}
                defaultStore={defaultStore}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                        {
                            actionCn: '查看profile',
                            actionKey: 'view-profile',
                        },
                    ];
                    props.onAction = async (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setActionAsync('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (key === 'view-profile') {
                            // 用id换url
                            const profileUrl = await fetchUserProfile(
                                data.contacts_phone,
                            );
                            if (profileUrl) {
                                voyagerTrack('crm_view_contacts_profile', {
                                    sense: 2,
                                });
                                window.open(profileUrl, '_blank');
                            }
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer_id') !== -1) {
                                setDrawerVisCus(true);
                                setCidCus(data.customer_id?.originalValue);
                            }

                            if (key.indexOf('/lead_id') !== -1) {
                                setLeadDrawerLid(data.lead_id?.originalValue);
                                setLeadDrawerVis(true);
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/contacts_name') !== -1) {
                                setDetailId(data.id);
                                setDrawerVis(true);
                                setCid(data.customer_id?.originalValue);
                            }
                        } else if (key === 'see-dupli-detail') {
                            defaultStore.setAction('see-dupli-detail');
                            defaultStore.setDupDetailContacts(data);
                        }
                    };
                    props.closeRowSelection = false;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    props.widthInfoKey = withInfoKey;
                    props.actionsWidth = 200;
                    return props;
                }}
                cellsSelectorTransformer={originalbaiscCellsSelector => {
                    const baiscCellsSelector = [...originalbaiscCellsSelector];
                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'contacts_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParamContacts';
                            nextCellSelector.inputs.push({
                                paramName: 'contacts_name',
                            });
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'duplicate_flag',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'DuplicateFlag';
                            return nextCellSelector;
                        },
                    );

                    makeObjColClickable(baiscCellsSelector, 'customer_id');

                    makeObjColClickable(baiscCellsSelector, 'lead_id');

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
