const duration = 200;
let events = [];
let timer = null;
const slice = cb => {
    if (events && events.length) {
        const now = new Date().getTime();
        const lastTimestamp = events[events.length - 1].timestamp;
        // 超过一定时间后一起发送
        if (now - lastTimestamp > duration || events.length > 100) {
            cb && cb(null, events);
            events = [];
        }
    } else {
        clearInterval(timer);
        timer = null;
    }
};
export function composed(event, cb) {
    events.push(event);
    if (!timer) {
        timer = setInterval(() => {
            slice(cb);
        }, 20);
    }
}
