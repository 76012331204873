import React, { useEffect, useState, memo, useContext } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Form } from 'antd';
import { WidgetCommonObject } from '@/components/common/form/widgets';
import _ from 'lodash';
import './m-dispatch.scss';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MOpNameDisplayContainer } from '@/components';
import { tryRefresh } from '@/utils';
import voyagerTrack from '@/utils/voyager_track';
import { StoreContext } from '@/stores';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
// refreshFunc为了全局搜索的刷新使用
export const ActionMDispatch: React.FC<{
    customerList: CustomerListStore;
    refreshFunc?: () => void;
    isDetail?: boolean;
}> = observer(({ customerList, refreshFunc, isDetail }) => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const { defaultMSelect, mutatingData } = customerList;
    const { mSeletedData: dataFromMSelecte } = defaultMSelect;
    const mSeletedData = mutatingData ? [mutatingData] : dataFromMSelecte;
    const customerName = mutatingData?.customer_name;

    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [requiredDetected, setRequiredDetected] = useState(false);
    const inputHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '所有人必填',
          }
        : {};

    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = customerList.actionType === 'm-dispatch';

    const { defaultMeta: listMeta } = customerList;
    const pageSize = 100;

    // userInfo

    // 把owner_id的constraint抽出来
    const ownerCol = _.find(
        listMeta.objectMeta,
        item => item.fieldName === 'owner_id',
    );
    if (!ownerCol) {
        return <span>owner_id, meta info not found</span>;
    }
    const constraint = ownerCol.constraint;
    if (!constraint) {
        return <span>owner_id, constraint info not found</span>;
    }
    return (
        <Modal
            visible={visible}
            title={'分配客户'}
            onCancel={() => {
                customerList.resetAction();
                if (customerList.mutatingData && !isDetail) {
                    customerList.setMutatingData(null);
                }
                setUser(null);
            }}
            confirmLoading={loading}
            onOk={async () => {
                if (!user) {
                    return setRequiredDetected(true);
                }
                setLoading(true);
                const ok = await customerList.mDispatch(user);
                setLoading(false);
                if (ok) {
                    // 分配领取客户
                    voyagerTrack('crm_sale_source_assign', {
                        assign: true,
                        userId: authStore?.userInfo?.userId,
                        appEnv: process.env.REACT_APP_ENV,
                    });
                    runInAction(() => {
                        customerList.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['customer']);
                        refreshFunc && refreshFunc();
                    });
                }
            }}
        >
            <h4>
                {customerName ? (
                    <span>将【{customerName}】分配给所有人。</span>
                ) : (
                    <span>
                        将选择的{mSeletedData.length}个客户分配给所有人。
                    </span>
                )}
                <MOpNameDisplayContainer
                    defaultStore={customerList}
                    nameParam="customer_name"
                />
            </h4>
            <div className="customer-m-dispatch">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...inputHelps}
                            {...formItemLayout}
                            required
                            label={<span className="label">所有人</span>}
                        >
                            <WidgetCommonObject
                                k={'owner_id'}
                                value={user}
                                onChange={val => {
                                    setUser(val);
                                    setRequiredDetected(false);
                                }}
                                options={{
                                    placeholder: '请选择所有人',
                                    constraint,
                                    listMeta,
                                    pageSize,
                                }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
