import { isWeixinBrowser, isIOS } from './v-utils';

export async function request(url, opts) {
    let defaultOpts = {
        method: 'POST',
        credentials: 'include',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    let options = Object.assign({}, defaultOpts, opts);

    /**
     * 增加navigator.sendBeacon兼容方法，防止在页面关闭、离开或者刷新时打点失败
     * 兼容方案，当不兼容sendBeacon时则仍使用原有的方式,其中原有方式增加请求返回
     * 兼容列表为 https://caniuse.com/#search=sendbeacon
     * 因为该方法也会被服务端调用，所以对浏览器的navigator做额外判断
     * 非微信浏览器会走该逻辑，因为目前发现，iOS(12.3.1) 微信(7.0.4)自己的锅。navigator.sendBeacon 返回true，但是请求未发出，后面需要测试排查其它的微信版本以及iOS版本是否有同样的问题，暂且一刀切
     * Android(9)微信(7.0.4)华为p30 pro 暂时没问题
     */
    return await new Promise((resolve, reject) => {
        if (
            typeof navigator !== 'undefined' &&
            navigator.sendBeacon &&
            typeof navigator.sendBeacon === 'function' &&
            !window.location.href.includes('debug=1') &&
            !(isWeixinBrowser() && isIOS())
        ) {
            try {
                let result = navigator.sendBeacon(url, options.body);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        } else {
            return fetch(url, options)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        }
    });
}
