import qs from 'querystring';
// import Cookie from 'tiny-cookie';
import { request } from './utility/request';
import { md5Hash } from './utility/md5';
import { vuuid } from './utility/v-uuid';
import { xor } from './utility/xor';
import { getFromPageParam } from './utility/v-utils';
import { jsonSize } from './utility/jsonSize';
import { composed } from './utility/scheduler';
import * as Cookie from 'tiny-cookie';

const pageSessionID = vuuid();

class Voyager {
    static url = 'https://track.mm.taou.com/v2/track?'; // 请求链接
    static eventList = []; // 事件列表
    static currentDurableEvents = []; // 当前持续事件列表
    static defaultParams = {
        app: 'maimai',
        lang:
            typeof navigator !== 'undefined' &&
            (navigator.language || navigator.userLanguage),
        qilu: 0,
        from_page: getFromPageParam(pageSessionID),
        src_page: getFromPageParam(pageSessionID),
        page: '', // 初始化进行处理
        event_source: 'web',
        mm_app_id: 0,
        duration: 0,
        old_vczh:
            (window.chrome ? !window.chrome.runtime : null) ||
            window.navigator.webdriver, // detect webdriver by qilu
    }; // 默认参数

    constructor(options) {
        let opts = Object.assign({}, { needPageView: true }, options); // 默认needPageView为true
        this.initDefaultParams();
        if (opts.needPageView === true) {
            // 构造函数配置选项
            this.pageViewProcess(opts.pageViewParams || {});
        }
        // 尝试重新发送失败的数据
        this.rensendFailedData();
    }
    /**
     * 处理持续的页面停留事件，对page_view进行单独统计
     * @param {Object} params 额外参数
     * @param {String} event_key 用于区分同样name的多个event, 如果事件不会并行，可以为空
     */
    pageViewProcess(params = {}) {
        this.callPageViewEvent('begin', params);

        // 有关事件的兼容性，参见https://docs.taou.com/pages/viewpage.action?pageId=20688675 兼容性说明
        // 苹果手持设备或者desktop都会执行unload事件
        if (
            /iPhone|iPad|iTouch/i.test(navigator.userAgent) ||
            !this.mobileAndTabletcheck()
        ) {
            window.addEventListener('unload', () => {
                this.callPageViewEvent('end', params);
            });
        }

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this.callPageViewEvent('end', params);
            }

            // fires when app transitions from prerender, user returns to the app / tab.
            if (document.visibilityState === 'visible') {
                // 重新创建新的PageView持续事件
                this.callPageViewEvent('begin', params);
            }
        });
    }
    /**
     * 开始跟踪PageView
     * @param {String} type begin/end
     * @param {Object} params 额外参数
     */
    callPageViewEvent(type, params) {
        // 站内和站外分别进行调用客户端方法和js web sdk
        if (type === 'begin') {
            let durableEvent = this.initDurableEvent('page_view', params);
            // 因为站内已经有客户端的page_view的begin打点，所以去掉站内的客户端的调用
            if (!this.isInApp()) {
                this.track(durableEvent.events[0]);
            }
        } else if (type === 'end') {
            let endEvent = this.getCurrentPageViewEvent();
            // 因为站内已经有客户端的page_view的end打点，所以去掉站内的客户端的调用
            if (endEvent.length) {
                if (!this.isInApp()) {
                    this.track(endEvent[0].events[1]);
                }
            } else {
                throw new Error('get current page view event failed!');
            }
        }
    }
    /**
     * 初始化PageView持续事件(begin/end)配置项
     * @param {String} event_name 事件名称
     * @param {Object} params 额外参数
     * @return {Object} result 返回配置项信息
     */
    initDurableEvent(event_name, params) {
        let event_id = vuuid(); // 页面开始的event_id
        // 页面开始
        let beginOpts = {
            event_id: event_id,
            event_name: event_name,
            event_type: 'begin',
            params,
        };

        let endOpts = Object.assign({}, beginOpts, {
            begin_event_id: event_id,
            event_id: vuuid(),
            event_type: 'end',
        });

        let durableEvent = {
            event_id,
            event_name,
            events: [beginOpts, endOpts],
        };

        Voyager.currentDurableEvents.push(durableEvent); // 将持续事件压入队列中
        return durableEvent;
    }
    /**
     * 获取最新的PageView事件
     */
    getCurrentPageViewEvent() {
        return Voyager.currentDurableEvents
            .reverse()
            .filter(item => item.event_name === 'page_view');
    }

    /**
     * 净化参数，过滤掉保留参数。因为 ios debug 包下传保留字段会 crash 掉
     */
    purifierParams(params) {
        const reserved_params = [
            'event_name',
            'app',
            'event_type',
            'event_id',
            'event_key',
            'begin_event_id',
            'duration',
            'timestamp',
            'event_source',
            'uid',
            'feature',
            'platform',
            'imei',
            'idfa',
            'udid',
            'android_id',
            'gaid',
            'mac_address',
            'brand',
            'model',
            'os_version',
            'network',
            'carrier',
            'qilu',
            'lang',
            'country',
            'battery',
            'app_version_name',
            'app_version_code',
            'edition',
            'mm_app_id',
            'install_id',
            'push_enabled',
            'channel',
            'media_source',
            'agency',
            'store',
            'campaign',
            'launch_id',
            'raw_session_id',
            'time_since_raw_session_begin',
            'session_id',
            'adcode',
            'page',
            'from_page',
            'src_page',
            'browser_id',
            'service',
            'process_id',
            'service_ip',
            'params',
            'sdk_extra',
        ];
        let newParams = {};
        for (let key in params) {
            if (!reserved_params.includes(key)) {
                newParams[key] = params[key];
            } else {
                console.warn(
                    `Parameter ${key} is a reserved field, please change the field`,
                );
            }
        }
        return newParams;
    }
    /**
     * 追踪事件 public方法
     * @param {String} eventName 事件名称
     * @param {String} eventKey 持续事件的key, 用来区分用一个name的不同事件. 可能是feed_id之类的
     *                          ⚠️️️⚠️⚠️ 在event_type为single时，字段不会生效
     */
    trackEvent(event_name, event_key = '', params = {}) {
        if (typeof event_name !== 'string' || event_name === '') {
            throw new Error(
                'eventName is neccessary while call trackEvent fn ',
            );
        }

        if (
            (typeof params.event_type === 'undefined' ||
                params.event_type === 'single') &&
            event_key !== ''
        ) {
            console.warn(
                '%cparameter event_key will not work when event_type=single!!!',
                'background: #222; font-size: 24px; color: yellow',
            );
        }

        let _params = this.purifierParams(params);

        let options = {
            event_name,
            event_key,
            params: _params,
        }; // 初始化选项

        // let { MaiMai_Native } = window;
        // // 站内和站外分别进行调用客户端方法和js web sdk
        // if (this.isInApp()) {
        //   try {
        //     MaiMai_Native &&
        //       MaiMai_Native.log_v2_track_event &&
        //       MaiMai_Native.log_v2_track_event(event_name, JSON.stringify(_params));
        //   } catch (err) {
        //     throw new Error(err);
        //   }
        // } else {
        return this.track(options);
    }
    /**
     * 追踪事件 private方法
     * @param {Object} options 打点选项
     */
    async track(options) {
        if (this.isInApp()) {
            return;
        }

        let opts = Object.assign(
            {},
            options,
            this.getAfData(),
            Voyager.defaultParams,
        ); // 每次事件的配置选项, 合并af数据
        opts.event_id = opts.event_id || vuuid();
        opts.timestamp = new Date().getTime();
        opts.event_type = opts.event_type || 'single'; // event_type为single/begin/end
        if (
            typeof opts.params !== 'undefined' &&
            typeof opts.params === 'object'
        ) {
            // 修复params参数问题，不需要加额外参数，把opts.params和opts进行合并
            try {
                opts = Object.assign({}, opts.params, opts);
                delete opts.params;
            } catch (err) {
                throw new Error(
                    'stringify opts.params ',
                    opts.params,
                    ' failed',
                    err,
                );
            }
        }
        // 计算时长
        if (opts.event_type === 'end') {
            let currentBeiginEvent = Voyager.eventList.filter(
                item => item.event_id === opts.begin_event_id,
            );
            opts.duration = opts.timestamp - currentBeiginEvent[0].timestamp;
        }
        opts.browser_id = this.getBrowserId(); // 浏览器id
        opts.uid = this.getUid(); // 用户u
        // opts.country = this.getCountry(); // 暂时去掉

        if (!this.checkBodySize(opts)) {
            throw new Error('request body is larger than 8M!');
        }

        // wmx:将数据放到数组中，准备发送
        Voyager.eventList.push(opts);
        composed(opts, async (err, res) => {
            if (err) {
                throw new Error(err);
            }
            await this.send({ events: res });
        });

        return null;
    }
    /**
     *  持续事件开始
     *  @param {String} eventName 持续事件的名称
     *  @param {String} eventKey 持续事件的key, 用来区分用一个name的不同事件. 可能是feed_id之类的
     *  @param {Object} params 事件的自定义参数, json {"key1": "value1","key2": "value2"}
     *  @param {Boolean} endWithRawSession 如果为true，则在rawSession结束时，自动结束该Event
     */
    beginTrackEvent(event_name, event_key, params = {}, end_with_raw_session) {
        // 站内和站外分别进行调用客户端方法和js web sdk
        // if (this.isInApp()) {
        //   MaiMai_Native &&
        //     MaiMai_Native.log_v2_begin_event &&
        //     MaiMai_Native.log_v2_begin_event(
        //       event_name,
        //       event_key,
        //       JSON.stringify(params),
        //       end_with_raw_session
        //     );
        // } else {
        let options = {
            event_name,
            event_key,
            params,
            end_with_raw_session,
        };
        return this.track(options);
        // }
    }
    /**
     *  持续事件结束
     *  @param {String} eventName 持续事件的名称
     *  @param {String} eventKey 持续事件的key, 用来区分用一个name的不同事件. 可能是feed_id之类的
     *  @param {Object} params 事件的自定义参数, json {"key1": "value1","key2": "value2"}
     */
    endTrackEvent(event_name, event_key, params = {}) {
        // 站内和站外分别进行调用客户端方法和js web sdk
        // if (this.isInApp()) {
        //   MaiMai_Native &&
        //     MaiMai_Native.log_v2_end_event(
        //       event_name,
        //       event_key,
        //       JSON.stringify(params)
        //     );
        // } else {
        let options = {
            event_name,
            event_key,
            params,
        };
        return this.track(options);
        // }
    }
    // 检查发送数据SIZE，是否小于8M
    checkBodySize(json) {
        return jsonSize(json) <= 8 * 1024 * 1024;
    }
    // 获取参数k
    getK() {
        return this.getUid() || this.getBrowserId();
    }
    // 获取u
    getUid() {
        try {
            // let {
            //   share_data: {
            //     auth_info: { u },
            //   },
            // } = window;
            // return u === -1 ? '' : u.toString();
            return Cookie.get('crmuid') || '';
        } catch (err) {
            return '';
        }
    }
    // 获取浏览器指纹
    getBrowserId() {
        return Cookie.get('_buuid') || vuuid();
    }
    // 获取af数据，基本针对增长的投放
    getAfData() {
        let query = qs.parse(window.location.search.substr(1));
        let { regfr } = query;
        if (!regfr) return {};
        let arry = regfr.split('_');
        let result = /sem_(baidu|sogou|shenm)/i.exec(regfr);
        if (result && arry.length > 4) {
            let media_source = 'smsearch_int'; // 这几个搜索渠道都是怎么起的名字，醉了
            if (result[1] === 'baidu') {
                media_source = 'baidusearch_int';
            } else if (result[1] === 'sogou') {
                media_source = 'sogousearch';
            }
            let { length } = arry;
            return {
                agency: arry[length - 4],
                campaign: arry[arry.length - 2],
                media_source,
            };
        }
        return {};
    }
    isInApp() {
        return navigator.userAgent.match(/MaiMai/i);
    }
    // 获取国家信息
    async getCountry() {
        try {
            let { country } = await request('https://ipinfo.io', {
                method: 'GET',
            });
            return country;
        } catch (err) {
            throw new Error(
                'cannot get country info throughout ipinfo.io',
                err,
            );
        }
    }
    async send(opts) {
        if (!Array.isArray(opts.events) || !opts.events.length) return;

        let body = xor(JSON.stringify(opts), '42');
        let k = this.getK();
        let s = md5Hash(body, k);
        let q = this.parseWebSDKConfig();

        let result = await request(
            Voyager.url + `app=maimai&${q}k=${k}&s=${s}`,
            {
                body,
            },
        );

        // 在没有进入到发送队列的请求进行额外处理
        if (result === false) {
            let img = new Image();
            img.src = `/sdk/voyager/send?app=maimai&${q}k=${k}&s=${s}&body=${encodeURIComponent(
                body,
            )}`;
            img.onerror = () => {
                let cacheData = localStorage.getItem('sendFailedEvents');
                // 请求失败时，将数据存储到本地，下次进行重新上传, 存入数组
                if (cacheData) {
                    try {
                        let newData = JSON.parse(cacheData);
                        newData.events = [...newData.events, ...opts.events]; // 两个数组进行合并
                        localStorage.setItem(
                            'sendFailedEvents',
                            JSON.stringify(newData),
                        );
                    } catch (err) {
                        throw new Error('save cacheData failed!');
                    }
                } else {
                    localStorage.setItem(
                        'sendFailedEvents',
                        JSON.stringify(opts),
                    );
                }
            };
        }
        return result;
    }
    /**
     * 重发失败数据
     */
    async rensendFailedData() {
        const failedData = localStorage.getItem('sendFailedEvents');
        let allData = {};
        try {
            allData = JSON.parse(failedData);
        } catch (err) {
            allData = {};
        }

        if (allData?.events?.length) {
            const { events } = allData;
            try {
                // send every start 5 requests to avoid too large request data
                this.send({ events: events.splice(0, 5) });
                // 只重发一次，随机立马删除缓存数据
                // do set action again if any data exists after splicing data
                if (events.length) {
                    localStorage.setItem(
                        'sendFailedEvents',
                        JSON.stringify({ events }),
                    );
                } else {
                    localStorage.removeItem('sendFailedEvents');
                }
            } catch (err) {
                throw new Error(
                    'parse localStorage sendFailedEvents failed ' + err,
                );
            }
        }
    }
    // 解析websdkconfig参数
    parseWebSDKConfig() {
        let queryString = '';
        let query = qs.parse(
            decodeURIComponent(window.location.search.substr(1)),
        );
        if (query && query.web_sdk_config) {
            try {
                let queryObj = JSON.parse(query.web_sdk_config);
                if (typeof queryObj !== 'string') {
                    Object.entries(queryObj).forEach(([k, v]) => {
                        queryString += `${k}=${v}&`;
                    });
                }
            } catch (err) {
                throw new Error('failed to parse query.web_sdk_config', err);
            }
        }
        return queryString;
    }
    /**
     * 初始化通用/默认参数
     */
    initDefaultParams() {
        Voyager.defaultParams = Object.assign({}, Voyager.defaultParams, {
            page: this.getPageParams(),
            raw_session_id: vuuid(),
            page_session_id: pageSessionID,
        });
    }
    /**
     * 获取通用page参数，仅用于浏览器环境
     * 其它场景下，继承的子类可以覆盖该方法
     */
    getPageParams() {
        return typeof window !== 'undefined' ? window.location.href : '';
    }
    /**
     * 检测是手机或者平板电脑
     * from detectmobilebrowsers.com
     */
    mobileAndTabletcheck() {
        let check = false;
        (function(a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a,
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }
}

export default Voyager;
