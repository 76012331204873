import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    AntButton,
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    MOpResultDisplayContainer,
    loadingWrapper,
    loadingWrapperOver,
} from '@/components';
import {
    Menu,
    Icon,
    message,
    Tooltip,
    Spin,
    Alert,
    Tag,
    Dropdown,
    Button,
} from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, delay } from '@/utils';
import { observer } from 'mobx-react-lite';
import classname from 'classname';
import { AntDrawer } from '@/components/antd/drawer';
import FormDemoTable from './components/table';
import FormDemoForm from './components/form';
import FormDrawer from './components/form-drawer';
import { ActionDelete } from './components/delete';
import { ActionInvalidBizType } from './components/invalid-biztype';
import { ActionMDelete } from './components/m-delete';
import { ActionMDispatch } from './components/m-dispatch';
import { ActionMDrop } from './components/m-drop';
import { ActionMFetch } from './components/m-fetch';
import { ActionMTransoformSea } from './components/m-transform-sea';
import { ActionMTransoform } from './components/m-transform';
import { runInAction, toJS } from 'mobx';
import { dFilterHOC, useDefaultDFilterSnippets } from '@/containers/hoc';
import { CustomerListStore } from './store';
import { useFastFilterByUrl, useFirstLv4Menu } from '@/stores/utils';
import { tryRefresh, useBeforeFirstDataLoaded } from '@/utils/hooks';
import { Kurong } from './components/kurongyue';
import { ActionExport } from '../../activation-record/list/components/export';
import { CustomerFollowJobCreate } from '../../job/jobs/lead/components/created-by-cus';
import { setFilterByUrlKey } from '../../../../stores/utils/filter';
import voyagerTrack from '@/utils/voyager_track';
import CustomerLabelsModal from '@/components/common/customer-drawer/customer-labels-modal';
import TagSelectModal from './components/tag-select-modal';
import { useRequest } from 'ahooks';
import { labelPlanList, getLabelPlan } from '@/pages/om/tag/list/api';
import { AutoScrollable } from '@/components/common/filters-display/auto-scrollable';
import moment, { Moment } from 'moment';

export interface FastFilterType {
    fastFilterKey: string;
    label: string;
    value: [
        {
            condition: {
                fieldId: string;
                filterValue: string;
                operateType: string;
            };
        },
    ];
}

const CustomerList: React.FC<RouteComponentProps & {
    type: string; // 公海池，我的客户
    dfilters?: any;
}> = ({ type, dfilters }) => {
    const store = useContext(StoreContext);
    const [customerList] = useState(() => new store.CustomerListStore(type));
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('customer-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        condListbyFilterData,
        defaultFastFilters,
    } = customerList;

    const { mSeletedData, setMSelectedData, isMSelectionMode } = defaultMSelect;
    const { confirmedfilterData, resetFilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch, setSorter } = defaultSorter;

    const customerListFetch = useCallback(() => customerList.fetch(), []);
    const [error, loading, reload] = useNet(customerListFetch, {
        autoLoad: false,
        refreshKeys: ['customer', 'customer-main-list'],
    });

    const [visibleLabelsModal, setVisibleLabelsModal] = useState(false);
    const [visibleLabelsSelectModal, setVisibleLabelsSelectModal] = useState(
        false,
    );
    const [planId, setPlanId] = useState('');

    // 标签方案列表
    const { data: planList = [], refresh } = useRequest(() => labelPlanList());

    const { data: tagPlan, mutate } = useRequest(() => getLabelPlan(planId), {
        ready: !!planId,
        onSuccess() {
            mutate(data => {
                if (!data) return data;
                const label = data?.label.map(group => {
                    group.items = group.items?.filter(tag => tag.selected);
                    return group;
                });
                return { ...data, label };
            });
        },
        refreshDeps: [planId],
    });

    const [tagFilter, setTagFilter] = useState<any[]>();

    useEffect(() => {
        if (tagPlan) {
            const label = _.cloneDeep(tagPlan.label);
            setTagFilter(label);
        }
    }, [tagPlan]);

    useEffect(() => {
        // 标签筛选
        const tags = tagFilter?.reduce((prev, current) => {
            const items = current.items?.map((tag: any) => tag.id);
            return [...prev, ...items];
        }, []);
        defaultFilter.assignFilterData({
            label_list_query: {
                value: tags,
                isEmpty: false,
                widgetKey: 'filterEnum',
                ts: 0,
            },
        });
        defaultFilter.confirmFilterData();
    }, [tagFilter]);

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            runInAction(() => {
                defaultMeta.setTableId('customer');
                defaultMeta.setOpUsername(
                    userStore.userInfo.userId + '-' + type,
                );
                defaultFastFilters.setFilters(dfilters);
            });
            Promise.all([defaultPerm.fetch(), defaultMeta.fetch()]).then(() => {
                setPreRequiredReady(true);
            }); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            if (type === 'all') {
                setSorter({
                    columnKey: 'account_score',
                    order: 'descend',
                });
            }
            // 默认正常客户，主客户
            if (type === 'mine') {
                defaultFilter.assignFilterData({
                    relate_type: {
                        value: [0, 2],
                        isEmpty: false,
                        widgetKey: 'filterEnum',
                        ts: 0,
                    },
                });
                setSorter({
                    columnKey: 'maimai_threeday_active_hr_cnt',
                    order: 'descend',
                });
            }
            setFilterByUrlKey(defaultFilter);
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited, type]);

    useFirstLv4Menu(defaultFastFilters);

    useFastFilterByUrl(defaultFastFilters);

    useEffect(() => {
        runInAction(() => {
            customerList.setListType(type);
            defaultFastFilters.setType(type);
        });
    }, [type]);

    // 关闭标签
    const handleLabelClose = (id: number) => {
        if (!tagFilter) return;
        const filter = tagFilter.filter(group => {
            group.items = group.items.filter((tag: any) => {
                return tag.id !== id;
            });
            return !_.isEmpty(group.items);
        });
        setPlanId('');
        setTagFilter(filter);
    };

    const fastFilterList = [
        '3days-gaoqian', //近三天新增高潜
        'create_time', //今日新增
        'today-new-lead', //今日有线索
        'today-sys-recycle', //今日系统回收
    ];

    const filters = (
        <>
            <FiltersDisplay
                isShowCollection={true}
                objectMeta={defaultMeta.objectMeta}
                filterData={confirmedfilterData}
                sorterData={customerList.defaultSorter}
                defaultFilter={defaultFilter}
                selectedFastFilters={defaultFastFilters.selectedFastFilters}
                onClose={(key: string) => {
                    defaultFilter.onFilterClear(key);
                }}
                onConfirm={(filterMap: any, fastFilters: [FastFilterType]) => {
                    // 重置
                    for (let key in defaultFilter.filterData) {
                        confirmedfilterData[key] = {
                            ...defaultFilter.filterData[key],
                        };
                    }
                    // 合并
                    for (let key in filterMap) {
                        const filterItem = filterMap[key];
                        confirmedfilterData[key] = {
                            ...confirmedfilterData[key],
                            ...filterItem,
                        };
                    }
                    // 快捷筛选时间需要动态处理
                    fastFilters.forEach((item: FastFilterType) => {
                        const { fastFilterKey = '' } = item;
                        if (fastFilterList.includes(fastFilterKey)) {
                            const value =
                                fastFilterKey === '3days-gaoqian'
                                    ? moment()
                                          .subtract(3, 'days')
                                          .startOf('day')
                                          .format('YYYY-MM-DD HH:mm:ss')
                                    : moment()
                                          .startOf('day')
                                          .format('YYYY-MM-DD HH:mm:ss');

                            item.value[0].condition.filterValue = value;
                        }
                    });

                    defaultFilter.resetFilterData();
                    defaultFastFilters.setSelectedFastFilters(fastFilters);
                }}
            />

            <div
                style={{
                    padding: '0 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex' }}>
                    {tagFilter?.map(group => (
                        <Alert
                            style={{ marginRight: '10px', marginTop: '3px' }}
                            message={
                                <div>
                                    {group.name}：
                                    {group.items.map((tag: any) => (
                                        <Tag
                                            key={tag.id}
                                            closable
                                            color="blue"
                                            onClose={() =>
                                                handleLabelClose(tag.id)
                                            }
                                        >
                                            {tag.name}
                                        </Tag>
                                    ))}
                                </div>
                            }
                            type="warning"
                        />
                    ))}
                </div>
            </div>
        </>
    );

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(customerList);

    const { title, fastFilters } = useDefaultDFilterSnippets(customerList, {
        onFFSelect: ffItem => {
            // 埋点
            if (type === 'mine') {
                voyagerTrack('crm_my_customer_ff_click', {
                    ffkey: ffItem?.fastFilterKey,
                });
            } else {
                voyagerTrack('crm_all_customer_ff_click', {
                    ffkey: ffItem?.fastFilterKey,
                });
            }
        },
    });

    // if (isBeforeFirstLoaded || !preRequiredReady) {
    // if (!preRequiredReady) {
    //     return (
    //         <div style={{ height: '100%', paddingBottom: 42 }}>
    //             <BlockLoading2 outerStyle={{ backgroundColor: 'white' }} />
    //         </div>
    //     );
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const [
        customerFollowJobCreateTrigger,
        setCustomerFollowJobCreateTrigger,
    ] = useState(0);
    const popups = (
        <>
            <ActionExport defaultStore={customerList} />
            <MOpResultDisplayContainer defaultStore={customerList} />
            <Kurong customerList={customerList} />
            <ActionDelete customerList={customerList} />
            <ActionInvalidBizType customerList={customerList} />
            <ActionMDelete customerList={customerList} />
            <ActionMDispatch customerList={customerList} />
            <ActionMDrop customerList={customerList} />
            <ActionMFetch customerList={customerList} />
            <ActionMTransoformSea customerList={customerList} />
            <ActionMTransoform customerList={customerList} />
            <FormDrawer customerList={customerList} type={type} />
            <CustomerFollowJobCreate
                customers={mSeletedData}
                trigger={customerFollowJobCreateTrigger}
                onSuccess={() => {
                    customerList.resetAction();
                    setCustomerFollowJobCreateTrigger(0);
                    tryRefresh(['customer']);
                }}
                onClose={() => {
                    customerList.resetAction();
                    setCustomerFollowJobCreateTrigger(0);
                }}
            />
            <CustomerLabelsModal
                visible={visibleLabelsModal}
                customerIds={mSeletedData.map(item => item.id)}
                onClose={() => {
                    setVisibleLabelsModal(false);
                }}
                onSubmit={() => {
                    setVisibleLabelsModal(false);
                    customerList.resetAction();
                }}
            />
            <TagSelectModal
                visible={visibleLabelsSelectModal}
                initialValues={tagFilter?.reduce((prev, current) => {
                    prev[current.id] = current.items?.map((tag: any) => tag.id);
                    return prev;
                }, {} as any)}
                planList={planList}
                refresh={refresh}
                onClose={() => {
                    setVisibleLabelsSelectModal(false);
                }}
                onSubmit={values => {
                    setVisibleLabelsSelectModal(false);
                    setTagFilter(values);
                    customerList.resetAction();
                }}
            />
        </>
    );

    const menu = (
        <Menu
            onClick={e => {
                if (e.key === 'export') {
                }
            }}
        >
            <Menu.Item key="1">
                <a target="__blank" href={customerList.downloadUrl}>
                    <Icon type="user" />
                    导出
                </a>
            </Menu.Item>
        </Menu>
    );

    const globalOps = (
        <>
            {/* 公海客户不应展示新建客户按钮 */}
            {/* {type === 'mine' &&
                defaultPerm.getPermByTypeAndActionOriginal(
                    customerList.listType,
                )('export').visible && (
                    <AntButton
                        onClick={() => {
                            runInAction(() => {
                                customerList.setAction('create');
                                customerList.startNewData();
                            });
                        }}
                        type="primary"
                        size="large"
                    >
                        导出
                    </AntButton>
                )} */}
            {/* {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'export',
            ).visible && (
                <a href={customerList.downloadUrl} target="__blank">
                    <AntButton size="large">导出</AntButton>
                </a>
            )} */}

            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'export',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
            {type === 'mine' &&
                defaultPerm.getPermByTypeAndActionOriginal(
                    customerList.listType,
                )('create').visible && (
                    <AntButton
                        onClick={() => {
                            runInAction(() => {
                                customerList.setAction('create');
                                customerList.startNewData();
                                if (customerList.mutatingData) {
                                    customerList.mutatingData.source = 1;
                                }
                            });
                        }}
                        type="primary"
                        size="large"
                    >
                        新建客户
                    </AntButton>
                )}
            {type === 'mine' && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            customerList.setAction('kurongyue');
                        });
                    }}
                    size="large"
                >
                    我的库容余额
                </AntButton>
            )}
        </>
    );

    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': isMSelectionMode,
                'normal-mode': !isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{mSeletedData.length}条：</span>
            </div>
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'dispatch',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-dispatch');
                    }}
                    size="large"
                >
                    分配
                </AntButton>
            )}
            {/* {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'fetch',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-fetch');
                    }}
                    size="large"
                >
                    领取
                </AntButton>
            )} */}
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'drop',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-drop');
                    }}
                    size="large"
                >
                    退回
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'transform',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-transform');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'customer',
                        body: {
                            dataAuthCode: 'TRANSFER',
                            ids: mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    转移
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'transform-sea',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-transform-sea');
                    }}
                    size="large"
                >
                    转池
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'delete',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-delete');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'customer',
                        body: {
                            dataAuthCode: 'DEL',
                            ids: mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    删除
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndActionOriginal(customerList.listType)(
                'new-task',
            ).visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('m-newjobs');
                        setCustomerFollowJobCreateTrigger(
                            1 + customerFollowJobCreateTrigger,
                        );
                    }}
                    size="large"
                >
                    新建任务
                </AntButton>
            )}

            <AntButton
                onClick={() => {
                    setVisibleLabelsModal(true);
                }}
                size="large"
            >
                批量绑定标签
            </AntButton>
        </div>
    );

    const dataTable = (
        <FormDemoTable
            customerList={customerList}
            loading={loading}
            type={type}
            reload={reload}
        />
    );

    const filtersDisplay = (
        <AutoScrollable>
            <div style={{ display: 'flex' }}>
                {fastFilters}
                <Button.Group
                    style={{
                        whiteSpace: 'nowrap',
                        lineHeight: '32px',
                        paddingTop: '2px',
                    }}
                >
                    <Button
                        onClick={() => {
                            setVisibleLabelsSelectModal(true);
                        }}
                    >
                        标签筛选
                    </Button>
                    {!_.isEmpty(planList) && (
                        <Dropdown
                            overlay={
                                <Menu
                                    onClick={v => {
                                        setPlanId(v.key);
                                    }}
                                >
                                    {planList.map(plan => (
                                        <Menu.Item key={plan.id}>
                                            {plan.plan_name}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Button>
                                <Icon type="down" />
                            </Button>
                        </Dropdown>
                    )}
                </Button.Group>
            </div>
        </AutoScrollable>
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalCustomerList = dFilterHOC(observer(CustomerList), 'customer');
export default FinalCustomerList;
