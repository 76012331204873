import React, { useEffect, useMemo, useRef } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '../drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import {
    Icon,
    message,
    Menu,
    Select,
    DatePicker,
    Popover,
    Badge,
    Divider,
} from 'antd';
import { IDrawerCardListProps } from '../drawer/drawer-card-list/index';
import ItemUser, {
    IItemsUserProps,
} from '../../../components/common/items/user/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '../drawer/drawer-tabs/index';
import DrawCollapse from '@/components/common/drawer/drawer-collapse';
import { IDrawerCollapseProps } from '../drawer/drawer-collapse/index';
import DataReview from '../../../components/common/items/data-review/index';
import {
    defaultAxios,
    useNet,
    useRefresh,
    isNetSuccess,
    tryRefresh,
    getQueryAsObject,
} from '@/utils';
import _ from 'lodash';
import CustomerTeamMember, {
    ICustomerTeamMemberData,
    ICustomerAuthData,
} from './customer-team-member/index';
import FinalLeadList from './customer-leads-table';
import FinalOptsList from './customer-opts-table';
// import FinalContractList from './customer-contract-table';
import FinalContractList from '@/pages/sales-follow-up/opt/list/drawer/opt-contract-table/index';
import { CustomerLeadsCreate } from './customer-leads-create';
import { CustomerOptCreate } from './customer-opt-create';
import { CustomerContactsCreate } from './customer-contacts-create';
import FinalContactList from './customer-contacts-table';
import LogModal from '../log-modal';
import MaiTrend from '../mai-trend';
import KeyBehavior from '../key-behavior';
import {
    CustomerListStore,
    useAnyCustomerStore,
} from '@/pages/sales-follow-up/custom/list/store';
import { ActionDelay } from '@/pages/sales-follow-up/custom/list/components/delay';
import moment from 'moment';
import { CtxProvider } from '@/utils/context';
import AddFollowLog from '../add-follow-log-new';
import { DDetail } from '../editable-display/ddetail';
import { makeObjColClickable, applyCustomConfToWidget } from '../form';
import { ActionTransStandard } from '@/pages/sales-follow-up/custom/list/components/trans-standard';
import { runInAction } from 'mobx';
import { ActionMDrop } from '@/pages/sales-follow-up/custom/list/components/m-drop';
import { loadingWrapperOver } from '../block-loading';
import { CusSubCus } from './customer-sub-customer';
import { OptContractCreate } from '@/pages/sales-follow-up/opt/list/drawer/opt-contract-create';
import { MOpResultDisplay, MOpResultDisplayContainer } from '../multiple-op';
import { ActionMTransoform } from '@/pages/sales-follow-up/custom/list/components/m-transform';
import { ActionMDispatch } from '@/pages/sales-follow-up/custom/list/components/m-dispatch';
import { ActionMFetch } from '@/pages/sales-follow-up/custom/list/components/m-fetch';
import { applyCustomConfToCellsSelector } from '../form/advanced-filters';
import { isMultipleCell } from '../table';
import { CusTransLog } from './customer-transform-log';
import CirculationLogModal from './customer-circulation-log';
import { getCustomerTags } from '@/pages/sales-follow-up/custom/list/utils';
import { Job4Customer } from '@/pages/sales-follow-up/job/job4customer';
import { CustomerActivityFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus-activity';
import CustomerMaiRights from './customer-mai-rights';
import { CustomerLastestJobShortCut } from '@/pages/sales-follow-up/job/jobs/lead/components/shortcut';
import { CustomerFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus';
import voyagerTrack from '@/utils/voyager_track';
import { StarCus } from '@/pages/sales-follow-up/custom/list/components/star';
import CustomerLabelsModal from './customer-labels-modal';
import CustomerInfoCard from './customer-info-card';
import CustomerActionLogNew from './customer-action-log-new';
import Form from 'antd/es/form';
import { useRequest } from 'ahooks';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmCustomerDrawer {
    customerList: CustomerListStore;
    type: string;
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    cid?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
    forceReload?: boolean; // 如果要在外部强制刷新用户详情，则将该值变化为true
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        customer_name: string; // 客户名称
        id: string | number; // 客户id
        expire_time: string; // 过期时间
        high_sea_id: any; // 关联字段 - 公海池信息
        level: any; // 解析字段 - 客户级别
        owner_id: any; // 关联字段 - 客户所有人
    };
    teamMemberData: ICustomerTeamMemberData[];
    defaultAuthData: ICustomerAuthData[];
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const rt2Hover: any = {
    1: '首次认领客户后，7天内未新建活动记录，系统将自动回收',
    2: '最近一次活动记录之后，31天内未再次跟进客户并新建活动记录，系统将自动回收',
    3: '领取后150天未成交（至少一个付费产品已开通且在线），系统将自动回收',
    4: '最晚一个产品到期后，30天内未成功续约并完成产品开通，系统将自动回收',
    5: '最晚一个产品到期后，30天内未成功续约并完成产品开通，系统将自动回收',
    6: '延期申请通过后，系统自动将回收时间推迟30天，30天后延期保护失效，系统将按规则执行自动回收策略',
    7: '首次认领非标客户后，3天内未转为标准客户，系统将自动回收',
};
const CustomerDrawer: React.FC<ICrmCustomerDrawer> = props => {
    const {
        drawerVis,
        setDrawerVis,
        cid,
        destroyOnClose = false,
        onCreat,
        onEdit,
        forceReload = false,
        customerList,
        type: outerType,
    } = props;

    const { listType } = customerList;
    const childRef = useRef();
    const childModalShow = () => {
        console.log('父组件点击了');
        console.log('c.current', childRef);
    };

    const [precheckLoading, setPrecheckLoading] = useState<boolean>(false);
    const [optContractCreateTrigger, setOptContractCreateTrigger] = useState(0);

    const [drawerData, setDrawerData] = useState<any>({});
    const [logModalVis, setLogModalVis] = useState(false);
    const [circulationModalVis, setCirculationModalVis] = useState(false);
    const [customerLeadCreateTrigger, setCustomerLeadCreateTrigger] = useState(
        0,
    );
    const [customerOptCreateTrigger, setCustomerOptCreateTrigger] = useState(0);
    const [
        customerContactsCreateTrigger,
        setCustomerContactsCreateTrigger,
    ] = useState(0);

    const [
        customerActivityFollowJobCreateTrigger,
        setCustomerActivityFollowJobCreateTrigger,
    ] = useState(0);

    const [
        customerFollowJobCreateTrigger,
        setCustomerFollowJobCreateTrigger,
    ] = useState(0);

    const [isNewCustomer, setIsNewCustomer] = useState(-1);

    const [createdFollowLog, setCreatedFollowLog] = useState<any>(null);

    const [tabNumberInfo, setTabNumberInfo] = useState<{
        lead: number;
        opportunity: number;
        contacts: number;
        contract: number;
    }>({
        lead: 0,
        opportunity: 0,
        contacts: 0,
        contract: 0,
    });

    const [visibleLabelsModal, setVisibleLabelsModal] = useState(false);
    const [childAuthKey, setChildAuthKey] = useState<any>();
    const [showChildeModal, setShowChildModal] = useState(false);

    const [prepared, defaultCustomerListStore, prepare] = useAnyCustomerStore({
        autoLoad: false,
    });

    const { defaultPerm } = defaultCustomerListStore;

    useEffect(() => {
        if (drawerVis) {
            prepare();
        }
    }, [drawerVis]);

    const [eventCode, setEventCode] = useState<string | undefined>(undefined);
    const [formEventCode, setFormEventCode] = useState<string | undefined>(
        undefined,
    );
    const isPrivateCus = eventCode === 'PRIVATE-VIEW';
    const type = outerType
        ? outerType
        : eventCode === 'PUBLIC-VIEW'
        ? 'all'
        : 'mine';

    const loadDetail = () => {
        if (drawerVis && prepared) {
            if (cid) {
                defaultCustomerListStore.fetchInstantlyMutatingDataById(cid);
            } else {
                defaultCustomerListStore.setMutatingData(null);
            }
        } else {
            defaultCustomerListStore.setMutatingData(null);
        }
    };

    useEffect(() => {
        if (null === defaultCustomerListStore.mutatingData) {
            return;
        }
        const {
            customer_status,
            // is_new,
            cooperation_status,
        } = defaultCustomerListStore.mutatingData;
        console.log('合作状态', cooperation_status);
        setIsNewCustomer(cooperation_status); // 改为用cooperation_status判断新客,
        // defaultCustomerListStore.mutatingData 里可能有customer类型 isNew
        if (customer_status !== 2) {
            setEventCode('PRIVATE-VIEW');
            setFormEventCode('PRIVATE-EDIT');
        } else {
            setEventCode('PUBLIC-VIEW');
            setFormEventCode('PUBLIC-EDIT');
        }
    }, [defaultCustomerListStore.mutatingData]);

    useEffect(loadDetail, [prepared, drawerVis, cid]);
    useRefresh(
        ['customer'],
        () => {
            loadDetail();
            fetchCustomerTabNumber();
        },
        [drawerVis, cid, prepared],
    );

    useRefresh(
        ['close-drawer'],
        () => {
            setDrawerVis(false);
        },
        [setDrawerVis],
    );

    const [drawerTabsValue, setDrawerTabsValue] = useState('8');
    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);
    const [mustLoading, setMustLoading] = useState(false); // 如果没有cid，就一直loading
    const [dataFail, setDataFail] = useState(false);
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};
    const fetchCustomerTabNumber = async () => {
        const url = '/bff/api/rest/customer/tab-info-number';
        const [d, e] = await defaultAxios.get(url, {
            cid,
        });
        if (d === null || d.data === null) {
            return null;
        }
        if (d && d.data) {
            setTabNumberInfo(d.data);
        }
    };
    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisCid = cid;
        setMustLoading(true);
        if (_.isNil(cid)) {
            return [null, null];
        }
        const { fieldAuthCode, dataAuthCode } = customerList;
        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/customer/detail?id=${cid}&fieldAuthCode=${fieldAuthCode}&dataAuthCode=${dataAuthCode}`,
        );
        if (d === null || d.data === null) {
            setDataFail(true);
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            setMustLoading(false);
            if (
                !d.data.originData ||
                _.isEmpty(d.data.originData) ||
                !d.data.originData?.length
            ) {
                setDataFail(true);
                return [null, new Error('no auth')];
            }
            if (thisCid === cid) {
                setDataFail(false);
                setDrawerData(d.data);
            }
        } else {
            setDataFail(true);
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['customer'],
    });

    const { loading: cleanDoneLoading, run: cleanDoneAction } = useRequest(
        id => {
            return defaultAxios.post(`/crm/customer/cleanDone/${id}`);
        },
        {
            manual: true,
            onSuccess(data) {
                const [d, e] = data;
                if (!e) {
                    message.success('清洗完成');
                }
            },
        },
    );

    useEffect(() => {
        const queryObj = getQueryAsObject();
        if (queryObj?.newjobs) {
            runInAction(() => {
                customerList.setAction('m-newjobs');
                setCustomerFollowJobCreateTrigger(
                    1 + customerFollowJobCreateTrigger,
                );
            });
        }
    }, [customerList]);

    useEffect(() => {
        if (drawerVis) {
            // if (cid) {
            reload();
            fetchCustomerTabNumber();
            // }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
            setDataFail(false);
        }
    }, [cid, drawerVis]);
    useEffect(() => {
        reload();
    }, [forceReload]);

    useEffect(() => {
        if (!defaultCustomerListStore.mutatingData) {
            return;
        }
        const br = defaultCustomerListStore.mutatingData?.business_registration;
        if (!br) {
            return;
        }
    }, [defaultCustomerListStore.mutatingData]);

    const staticData = drawerData?.staticData || {};
    const originData = drawerData?.originData
        ? drawerData?.originData[0] || {}
        : {};
    console.log('originData', originData);
    const actionLogConfig = drawerData?.actionLogConfig || {};

    const {
        isNotStanderCustomer,
        haveCID,
        isLock,
        isChildrenCustomer,
        isNewEconomyCompany_Toubu,
        isNewEconomyCompany_Yaobu,
        isKP,
        isGaoqian,
    } = getCustomerTags(originData);

    const labels = useMemo(() => {
        return originData?.label_list?.map((tag: any) => tag.name);
    }, [originData]);

    const isStandard = !isNotStanderCustomer;

    const titleExampleConfig: IDrawerTitleProps = {
        title: staticData ? (
            <>
                <span
                    className="iconfont1"
                    style={{
                        color: '#0052ff',
                        fontSize: '18px',
                        marginRight: '8px',
                    }}
                >
                    &#xe619;
                </span>
                {staticData.customer_name}&nbsp;
                {/* <span style={{ position: 'relative', top: -3, left: 5 }}>
                    <BusinessRegistrationDisplay
                        isBusinessRegistration={
                            +originData?.business_registration === 1
                        }
                        size="big"
                    />
                </span> */}
                {originData?.simple_info && (
                    <Popover
                        placement="bottom"
                        content={
                            <div style={{ width: '600px' }}>
                                {originData.simple_info}
                            </div>
                        }
                        getPopupContainer={() => {
                            const target = document.querySelector(
                                '.customer-simple_info',
                            ) as HTMLElement;
                            console.info('getPopupContainer target: ', target);
                            if (!target) {
                                return document.body as HTMLElement;
                            }
                            return target;
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: '4px',
                                color: 'rgb(175, 177, 188)',
                                fontSize: '16px',
                                lineHeight: '18px',
                            }}
                            className="customer-simple_info"
                            type="exclamation-circle"
                        />
                    </Popover>
                )}
                <div
                    style={{
                        display: 'inline-block',
                        position: 'relative',
                    }}
                >
                    <a
                        target="_blank"
                        href={`https://www.baidu.com/s?wd=${encodeURIComponent(
                            staticData.customer_name,
                        )}`}
                    >
                        <span
                            className="iconfont1 search-icon"
                            style={{
                                cursor: 'pointer',
                                fontSize: 18,
                                color: '#2932e1',
                            }}
                        >
                            &#xe6e3;
                        </span>
                    </a>
                    <a
                        target="_blank"
                        href={`https://www.qcc.com/web/search?key=${encodeURIComponent(
                            staticData.customer_name,
                        )}`}
                    >
                        <span
                            className="iconfont1 search-icon"
                            style={{
                                cursor: 'pointer',
                                fontSize: 20,
                                paddingLeft: 4,
                                position: 'relative',
                                top: 1,
                                color: '#128bed',
                            }}
                        >
                            &#xe64b;
                        </span>
                    </a>
                    <a
                        target="_blank"
                        href={`https://www.tianyancha.com/search?key=${encodeURIComponent(
                            staticData.customer_name,
                        )}`}
                    >
                        <span
                            className="iconfont1 search-icon"
                            style={{
                                cursor: 'pointer',
                                fontSize: 18,
                                paddingLeft: 4,
                                paddingRight: 6,
                                position: 'relative',
                                color: '#0084ff',
                            }}
                        >
                            &#xe6dc;
                        </span>
                    </a>
                    <a
                        target="_blank"
                        href={`https://www.liepin.com/zhaopin/?key=${encodeURIComponent(
                            staticData.customer_name,
                        )}`}
                    >
                        <img
                            src="//i9.taou.com/maimai/p/34452/8678_6_4JwCQQEH0kUxw6"
                            style={{
                                paddingRight: '6px',
                                height: '18px',
                                verticalAlign: 'top',
                            }}
                        />
                    </a>
                    <a
                        target="_blank"
                        href={`https://www.zhipin.com/web/geek/job?query=${encodeURIComponent(
                            staticData.customer_name,
                        )}`}
                    >
                        <img
                            src="//i9.taou.com/maimai/p/34476/9232_6_7LKXxLmWrwVw5Y"
                            style={{
                                width: '18px',
                                height: '18px',
                                verticalAlign: 'top',
                            }}
                        />
                    </a>
                </div>
                <br />
            </>
        ) : null,
        icons: [],
        afterTags:
            originData?.id !== undefined &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('star').visible ? (
                <span style={{ position: 'relative', top: -2, left: 3 }}>
                    <StarCus
                        cus={originData}
                        customerList={customerList}
                        defaultStared={originData?.focus || false}
                        onOk={() => {
                            tryRefresh(['customer-main-list']);
                        }}
                    />
                </span>
            ) : null,
        tags: [
            {
                content: '非标',
                color: '#FFF',
                style: {
                    width: '32px',
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid #ff001f',
                    display: 'inline-block',
                    marginRight: 2,
                    color: '#ff001f',
                    padding: 0,
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: isNotStanderCustomer,
            },
            {
                content: '锁定',
                color: '#FFF',
                style: {
                    width: '32px',
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid #ff001f',
                    display: 'inline-block',
                    marginRight: 2,
                    marginLeft: '5px',
                    color: '#ff001f',
                    padding: 0,
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: isLock,
            },
            {
                content: '子客户',
                color: '#FFF',
                style: {
                    width: '48px',
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid #FFA408',
                    display: 'inline-block',
                    marginRight: 2,
                    color: '#FFA408',
                    marginLeft: '5px',
                    padding: 0,
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: isChildrenCustomer,
            },
            // {
            //     content: '无CID',
            //     color: '#FFF',
            //     style: {
            //         width: '48px',
            //         height: '16px',
            //         lineHeight: '14px',
            //         fontSize: '12px',
            //         borderRadius: '4px',
            //         border: '1px solid #FFA408',
            //         display: 'inline-block',
            //         marginLeft: '5px',
            //         marginRight: 2,
            //         color: '#FFA408',
            //         padding: 0,
            //         position: 'relative',
            //         top: -3,
            //         textAlign: 'center',
            //         transform: 'scale(1.1)',
            //         backgroundColor: '#FFF',
            //     },
            //     show: !haveCID,
            // },
            {
                content: '跨区',
                color: '#FFF',
                style: {
                    width: '32px',
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid #FFA408',
                    display: 'inline-block',
                    marginRight: 2,
                    marginLeft: '5px',
                    color: '#FFA408',
                    padding: 0,
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: !!originData?.cross_region_tag,
            },
            // {
            //     content: '新经济',
            //     color: '#FFF',
            //     style: {
            //         height: '16px',
            //         lineHeight: '14px',
            //         fontSize: '12px',
            //         borderRadius: '4px',
            //         border: '1px solid rgb(44, 163, 31)',
            //         display: 'inline-block',
            //         marginRight: 2,
            //         marginLeft: '5px',
            //         color: 'rgb(44, 163, 31)',
            //         padding: '0 4px',
            //         position: 'relative',
            //         top: -3,
            //         textAlign: 'center',
            //         transform: 'scale(1.1)',
            //         backgroundColor: '#FFF',
            //     },
            //     show: isNewEconomyCompany_Toubu,
            // },
            // {
            //     content: '新经济',
            //     color: '#FFF',
            //     style: {
            //         height: '16px',
            //         lineHeight: '14px',
            //         fontSize: '12px',
            //         borderRadius: '4px',
            //         border: '1px solid rgb(44, 163, 31)',
            //         display: 'inline-block',
            //         marginRight: 2,
            //         marginLeft: '5px',
            //         color: 'rgb(44, 163, 31)',
            //         padding: '0 4px',
            //         position: 'relative',
            //         top: -3,
            //         textAlign: 'center',
            //         transform: 'scale(1.1)',
            //         backgroundColor: '#FFF',
            //     },
            //     show: isNewEconomyCompany_Yaobu,
            // },
            {
                content: '高价值',
                color: '#FFF',
                style: {
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid rgb(44, 163, 31)',
                    display: 'inline-block',
                    marginRight: 2,
                    marginLeft: '5px',
                    color: 'rgb(44, 163, 31)',
                    padding: '0 4px',
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: isKP,
            },
            {
                content: '高潜',
                color: '#FFF',
                style: {
                    height: '16px',
                    lineHeight: '14px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    border: '1px solid rgb(44, 163, 31)',
                    display: 'inline-block',
                    marginRight: 2,
                    marginLeft: '5px',
                    color: 'rgb(44, 163, 31)',
                    padding: '0 4px',
                    position: 'relative',
                    top: -3,
                    textAlign: 'center',
                    transform: 'scale(1.1)',
                    backgroundColor: '#FFF',
                },
                show: isGaoqian,
            },
        ],
        buttons: [],
        label: {
            tags: labels,
            action: () => {
                setVisibleLabelsModal(true);
            },
        },
    };
    let isCustomerInfoEditAble = false;
    const permEdit = defaultPerm.getPermByTypeAndActionOriginal(type)(
        type === 'all' ? 'edit' : 'update',
    ).visible;

    if (
        _.isArray(titleExampleConfig.buttons) &&
        defaultPerm.getPermByTypeAndActionOriginal(type)('clean-down').visible
    ) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                children: '清洗',
                loading: cleanDoneLoading,
                onClick: () => {
                    cleanDoneAction(cid);
                },
            },
        });
    }

    if (prepared && permEdit && _.isArray(titleExampleConfig.buttons)) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                children: '编辑',
                onClick: () => {
                    // tslint:disable-next-line:no-unused-expression
                    typeof onEdit === 'function' &&
                        onEdit(
                            drawerData?.originData
                                ? drawerData?.originData[0]
                                : {},
                        );
                },
            },
        });
    }

    if (prepared && titleExampleConfig.buttons) {
        if (
            !defaultCustomerListStore.mutatingDataLoading &&
            !isStandard &&
            isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)(
                'transfer-standard',
            ).visible
        ) {
            // 私池 & 非标准
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    children: '转标准客户',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        defaultCustomerListStore.setAction('trans-standard');
                    },
                },
            });
        }

        // 私池才有退回
        if (
            isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('drop').visible
        ) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    children: '退回',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        runInAction(() => {
                            defaultCustomerListStore.setAction('m-drop');
                        });
                    },
                },
            });
        }
        // 私池才有转移
        if (
            isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('transform')
                .visible &&
            !!originData?.id
        ) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    checkBody: originData?.id
                        ? {
                              tableCode: 'customer',
                              body: {
                                  dataAuthCode: 'TRANSFER',
                                  ids: [originData.id],
                              },
                          }
                        : undefined,
                    children: '转移',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        runInAction(() => {
                            defaultCustomerListStore.defaultMSelect.setMSelectedData(
                                [defaultCustomerListStore.mutatingData],
                            );
                            defaultCustomerListStore.setAction('m-transform');
                        });
                    },
                },
            });
        }

        // 私池新建任务
        if (
            isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('new-task')
                .visible &&
            !!originData?.id
        ) {
            titleExampleConfig.buttons.push({
                type: 'button',
                buttonConfig: {
                    children: '新建任务',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        runInAction(() => {
                            customerList.setAction('m-newjobs');
                            setCustomerFollowJobCreateTrigger(
                                1 + customerFollowJobCreateTrigger,
                            );
                        });
                    },
                },
            });
        }

        // 公池分配
        if (
            !isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('dispatch').visible
        ) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    children: '分配',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        runInAction(() => {
                            defaultCustomerListStore.setAction('m-dispatch');
                        });
                    },
                },
            });
        }

        // 公池领取
        if (
            !isPrivateCus &&
            defaultPerm.getPermByTypeAndActionOriginal(type)('fetch').visible
        ) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    children: '领取',
                    loading: defaultCustomerListStore.mutatingDataLoading,
                    onClick: () => {
                        runInAction(() => {
                            defaultCustomerListStore.setAction('m-fetch');
                        });
                    },
                },
            });
        }
    }

    if (prepared) {
        if (isPrivateCus && !!originData?.id) {
            isCustomerInfoEditAble = true;
        }
    }

    const permAppDelay = defaultPerm.getPermByTypeAndActionOriginal(type)(
        'apply-delay',
    ).visible;
    const permViewOpRecord =
        type === 'mine'
            ? defaultPerm.getPermByTypeAndActionOriginal(type)(
                  'view-operate-record',
              ).visible
            : defaultPerm.getPermByTypeAndActionOriginal(type)(
                  'view-edit-record',
              ).visible;

    const permTransformRecord = defaultPerm.getPermByTypeAndActionOriginal(
        type,
    )('view-transform-record').visible;

    const permFollow = !!defaultPerm.getPermByTypeAndActionOriginal(type)(
        'follow-up',
    ).visible;

    const dropMenu = (
        <Menu
            onClick={async e => {
                console.log('click', e);
                if (e.key === '1') {
                    setLogModalVis(true);
                } else if (e.key === '2') {
                    setCustomerLeadCreateTrigger(1 + customerLeadCreateTrigger);
                } else if (e.key === '3') {
                    if (sysUser.id !== originData.owner_id?.originalValue) {
                        message.error('不能创建客户所有人不是自己的商机');
                        return;
                    }
                    setCustomerOptCreateTrigger(1 + customerOptCreateTrigger);
                } else if (e.key === '4') {
                    setCustomerContactsCreateTrigger(
                        1 + customerContactsCreateTrigger,
                    );
                } else if (e.key === '5') {
                    defaultCustomerListStore.setAction('delay');
                } else if (e.key === '6') {
                    // defaultAxios
                    message.loading('请稍等');
                    const res = await defaultCustomerListStore.refreshBusinessRegistration();
                    if (res) {
                        tryRefresh(['customer']);
                    }
                } else if (e.key === '6-2') {
                    // defaultAxios
                    message.loading('请稍等');
                    const res = await defaultCustomerListStore.refreshBusinessRegistrationQichacha();
                    if (res) {
                        tryRefresh(['customer']);
                    }
                } else if (e.key === '7') {
                    setCirculationModalVis(true);
                } else if (e.key === '8') {
                    const hideLoading = message.loading('请稍等');
                    const res = await defaultCustomerListStore.refreshCooStatus();
                    hideLoading();
                    if (res) {
                        tryRefresh(['customer']);
                    }
                }
            }}
        >
            {permAppDelay ? <Menu.Item key="5">延期申请</Menu.Item> : null}
            {permViewOpRecord ? (
                <Menu.Item key="1">查看操作记录</Menu.Item>
            ) : null}
            {permTransformRecord ? (
                <Menu.Item key="7">查看客户流转记录</Menu.Item>
            ) : null}
            {permEdit ? <Menu.Item key="6">更新工商信息</Menu.Item> : null}
            {permEdit ? (
                <Menu.Item key="6-2">更新工商信息(企查查)</Menu.Item>
            ) : null}
            {permEdit ? <Menu.Item key="8">更新合作状态</Menu.Item> : null}
        </Menu>
    );

    if (
        prepared &&
        titleExampleConfig.buttons &&
        (permAppDelay || permViewOpRecord)
    ) {
        titleExampleConfig.buttons.push({
            type: 'drop',
            dropButtonConfig: {
                overlay: dropMenu,
                buttonConfig: {
                    type: 'default',
                    style: {
                        padding: 0,
                        backgroundColor: 'rgb(244, 245, 250)',
                    },
                    children: (
                        <span
                            className="iconfont1"
                            style={{
                                fontSize: '12px',
                                transform: 'scale(0.35)',
                            }}
                        >
                            &#xe618;
                        </span>
                    ),
                },
            },
        });
    }

    if (dataFail) {
        titleExampleConfig.buttons = [];
    }

    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '客户所有人',
                content: staticData?.owner_id?.relatedObj?.name || '- -',
            },
            {
                title: '客户公海池',
                content:
                    staticData?.high_sea_id?.relatedObj?.description || '- -',
            },
            // cooperation_status
            {
                title: '合作状态',
                content: (() => {
                    const cooperation_status = originData?.cooperation_status;
                    if (_.isNil(cooperation_status)) {
                        return '- -';
                    }
                    const metas =
                        defaultCustomerListStore?.defaultMeta?.objectMeta || [];
                    const metaItem = metas.find(
                        (item: any) => item.fieldName === 'cooperation_status',
                    );
                    if (_.isNil(metaItem)) {
                        return '- -';
                    }
                    const item = (
                        metaItem?.constraint?.constraintItemList || []
                    ).find(
                        (item: any) =>
                            item.constraintValue === cooperation_status,
                    );
                    return item?.constraintLabel || '- -';
                })(),
            },
            {
                title: '客户级别',
                content: staticData?.level?.constraintLabel || '- -',
            },
            {
                title: '认领时间',
                content: originData?.claim_time
                    ? moment(originData?.claim_time).format('YYYY-MM-DD')
                    : '- -',
            },
            {
                title: '到期时间',
                content: originData?.expire_time
                    ? (() => {
                          const moExpireTime = moment(
                              originData?.expire_time,
                          ).startOf('day');
                          const moNow = moment().startOf('day');
                          const diff = moExpireTime.diff(moNow, 'days');

                          if (diff > 0) {
                              return (
                                  moExpireTime.format('YYYY-MM-DD') +
                                  `(剩余${moExpireTime.diff(moNow, 'days')}天)`
                              );
                          }
                          return (
                              <span style={{ color: '#ff001f' }}>
                                  {moExpireTime.format('YYYY-MM-DD')}
                              </span>
                          );
                      })()
                    : '- -',
            },
            {
                title: '回收类型',
                content: (() => {
                    const recycle_type = staticData?.recycle_type;
                    if (_.isNil(recycle_type)) {
                        return '- -';
                    }
                    const metas =
                        defaultCustomerListStore?.defaultMeta?.objectMeta || [];
                    const metaItem = metas.find(
                        (item: any) => item.fieldName === 'recycle_type',
                    );
                    if (_.isNil(metaItem)) {
                        return '- -';
                    }
                    const item = (
                        metaItem?.constraint?.constraintItemList || []
                    ).find(
                        (item: any) => item.constraintValue === recycle_type,
                    );
                    return item?.constraintLabel ? (
                        <Popover
                            placement="bottomLeft"
                            content={rt2Hover?.[recycle_type]}
                        >
                            <span style={{ color: '#ff001f' }}>
                                {item?.constraintLabel}
                            </span>
                        </Popover>
                    ) : (
                        '- -'
                    );
                })(),
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;

    const getTabPerm = (key: string) => {
        return !defaultPerm.inited || defaultPerm.loading
            ? false
            : defaultPerm.getPermByTypeAndActionOriginal(type)(key).visible;
    };

    useEffect(() => {
        if (getTabPerm('key-behavior-tab')) {
            setDrawerTabsValue('10');
        }
    }, [getTabPerm('key-behavior-tab')]);

    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            // defaultActiveKey: '10',
            tabBarGutter: 24,
            onChange: (activeKey: string) => {
                if (activeKey === '9') {
                    voyagerTrack('crm_tab_mai_rights_click', {
                        tabkey: '9',
                    });
                }
                setDrawerTabsValue(activeKey);
            },
            activeKey: drawerTabsValue,
            tabConfig: [
                {
                    key: '1',
                    tab: '客户信息',
                    content: loadingWrapperOver(!eventCode)(
                        <>
                            <DDetail
                                defaultStore={defaultCustomerListStore}
                                eventCode={eventCode}
                                cellsSelectorTransformer={originalbaiscCellsSelector => {
                                    const baiscCellsSelector = [
                                        ...originalbaiscCellsSelector,
                                    ];
                                    // 详情可点击
                                    makeObjColClickable(
                                        baiscCellsSelector,
                                        'parent_customer_id',
                                    );

                                    applyCustomConfToCellsSelector(
                                        baiscCellsSelector,
                                        'compete_web_url',
                                        cellSelector => {
                                            if (isMultipleCell(cellSelector)) {
                                                return cellSelector;
                                            }
                                            cellSelector.cell = 'MLinks';
                                            return cellSelector;
                                        },
                                    );
                                    return baiscCellsSelector;
                                }}
                                widgetsTransformer={baiscWidgets => {
                                    applyCustomConfToWidget(
                                        baiscWidgets,
                                        'customer_business_card',
                                        prevWidget => {
                                            const nextWidget = _.cloneDeep(
                                                prevWidget,
                                            );
                                            nextWidget.widgetKey = 'upload';
                                            nextWidget.uploadOptions = {
                                                multiple: true,
                                                fileExtWhitelist: [
                                                    'jpg',
                                                    'png',
                                                    'jpeg',
                                                ],
                                                lint:
                                                    '附件要求:2M以内,支持文件格式为jpg,png,jpeg',
                                                limit: 2 * 1024 * 1024,
                                                overlimitLint:
                                                    '名片大小不能超过2M',
                                            };
                                            return nextWidget;
                                        },
                                    );
                                    return baiscWidgets;
                                }}
                                outerLoading={
                                    loading ||
                                    defaultCustomerListStore.mutatingDataLoading
                                }
                                formEventCode={formEventCode}
                                editableBlackList={
                                    new Set([
                                        'customer_name',
                                        'source',
                                        'city',
                                        'state',
                                        'district',
                                    ])
                                }
                            />
                            <CusSubCus
                                defaultStore={defaultCustomerListStore}
                            />
                            <CusTransLog
                                defaultStore={defaultCustomerListStore}
                            />
                        </>,
                    ),
                    visible: getTabPerm('basic-info-tab'),
                },
                {
                    key: '8',
                    tab: '脉动态',
                    content: (
                        <MaiTrend
                            refreshData={reload}
                            cid={cid}
                            maiTrendData={originData}
                            isPrivateCus={isPrivateCus}
                            customerId={originData.customer_cid}
                            defaultCustomerName={staticData.customer_name}
                        />
                    ),
                    visible: getTabPerm('mai-trend-tab'),
                },
                {
                    key: '10',
                    tab: '关键行为',
                    content: (
                        <KeyBehavior
                            cid={cid}
                            maiTrendData={originData}
                            isPrivateCus={isPrivateCus}
                            customerId={originData.customer_cid}
                            defaultCustomerName={staticData.customer_name}
                        />
                    ),
                    visible: getTabPerm('key-behavior-tab'),
                },
                {
                    content: (
                        <FinalLeadList
                            onCreate={() => {
                                setCustomerLeadCreateTrigger(
                                    1 + customerLeadCreateTrigger,
                                );
                            }}
                            // ...
                            type={eventCode === 'PUBLIC-VIEW' ? 'all' : 'mine'}
                            isStandard={isStandard}
                            drawerData={drawerData}
                            parentType={customerList.listType}
                        />
                        // null
                    ),
                    key: '2',
                    tab: <span>线索{`[${tabNumberInfo.lead}]`}</span>,
                    visible: getTabPerm('lead-tab'),
                },
                {
                    content: (
                        <FinalContactList
                            onCreate={() => {
                                setCustomerContactsCreateTrigger(
                                    1 + customerContactsCreateTrigger,
                                );
                            }}
                            drawerData={drawerData}
                            type="inner-cus-drawer"
                        />
                    ),
                    key: '3',
                    tab: <span>联系人{`[${tabNumberInfo.contacts}]`}</span>,
                    visible: getTabPerm('contacts-tab'),
                },
                {
                    content: (
                        <FinalOptsList
                            onCreate={() => {
                                setCustomerOptCreateTrigger(
                                    1 + customerOptCreateTrigger,
                                );
                            }}
                            precheckLoading={precheckLoading}
                            drawerData={drawerData}
                            type="inner-cus-drawer"
                        />
                    ),
                    key: '4',
                    tab: <span>商机{`[${tabNumberInfo.opportunity}]`}</span>,
                    visible: isStandard && getTabPerm('opt-tab'),
                },
                {
                    content: (
                        <FinalContractList
                            drawerData={drawerData}
                            type="inner-cus-drawer"
                            drawEntity={'customer'}
                            onCreate={() => {
                                setOptContractCreateTrigger(
                                    optContractCreateTrigger + 1,
                                );
                            }}
                        />
                    ),
                    key: '5',
                    tab: <span>合同{`[${tabNumberInfo.contract}]`}</span>,
                    visible: isStandard && getTabPerm('contract-tab'),
                },
                {
                    key: '9',
                    tab: '脉权益',
                    visible: isStandard && getTabPerm('mai-rights-tab'),
                    content: (
                        <CustomerMaiRights
                            cid={originData.customer_cid}
                            defaultCustomerName={staticData.customer_name}
                            crm_id={cid}
                        />
                    ),
                },
                {
                    // JOBTODO
                    // 任务或许也要有个统计了，也要加权限
                    // lead改成新的tableCode
                    content: (
                        <Job4Customer
                            type="all"
                            tableCode="task_activation"
                            defaultFilters={[
                                {
                                    fieldId: 'follow_customer',
                                    filterValue: originData.id,
                                    operateType: '=',
                                    parser: 'string',
                                },
                            ]}
                        />
                    ),
                    key: '19',
                    tab: <span>任务</span>,
                    visible: getTabPerm('task-tab'),
                },
            ],
        },
    };

    return (
        <CtxProvider
            ctx={{
                position: 'drawer',
                positionDetail: 'customer-drawer',
                data: { cid },
            }}
        >
            {originData && (
                <CustomerFollowJobCreate
                    customers={[originData]}
                    trigger={customerFollowJobCreateTrigger}
                    onSuccess={() => {
                        customerList.resetAction();
                        setCustomerFollowJobCreateTrigger(0);
                        tryRefresh(['customer']);
                    }}
                    onClose={() => {
                        customerList.resetAction();
                        setCustomerFollowJobCreateTrigger(0);
                    }}
                />
            )}
            <ActionDelay customerList={defaultCustomerListStore} />
            <ActionTransStandard
                customerList={defaultCustomerListStore}
                topCustomerList={customerList}
                setDrawerVis={setDrawerVis}
            />
            <ActionMTransoform customerList={defaultCustomerListStore} />
            <ActionMDispatch
                customerList={defaultCustomerListStore}
                isDetail={true}
            />
            <ActionMFetch
                customerList={defaultCustomerListStore}
                isDetail={true}
            />
            <ActionMDrop customerList={defaultCustomerListStore} />
            {defaultCustomerListStore.defaultMSelect.mopResult && (
                <MOpResultDisplayContainer
                    defaultStore={defaultCustomerListStore}
                />
            )}
            <LogModal
                modalVis={logModalVis}
                setModalVis={setLogModalVis}
                entityCode="customer"
                entityValueId={cid}
            />
            <CirculationLogModal
                customerId={cid}
                modalVis={circulationModalVis}
                setModalVis={setCirculationModalVis}
            />
            <OptContractCreate trigger={optContractCreateTrigger} />
            <CustomerLeadsCreate
                trigger={customerLeadCreateTrigger}
                customerInfo={originData}
            />

            <CustomerOptCreate
                onPrecheckLoadingChange={(nextLoading: boolean) => {
                    setPrecheckLoading(nextLoading);
                }}
                trigger={customerOptCreateTrigger}
                customerInfo={originData}
            />
            <CustomerContactsCreate
                trigger={customerContactsCreateTrigger}
                customerInfo={originData}
            />
            <CommonDrawer
                isFail={dataFail}
                destroy={destroyOnClose ? !drawerVis : false}
                loading={mustLoading || loading}
                titleConfig={titleExampleConfig}
                DrawerContentConfig={{
                    zIndex: 999,
                    placement: 'right',
                    onClose: () => {
                        setDrawerVis(false);
                    },
                    visible: drawerVis,
                }}
                // hideRightContent={drawerTabsValue === '8'}
                extraLeft={
                    <>
                        {originData?.id ? (
                            <CustomerLastestJobShortCut
                                cusId={originData?.id}
                            />
                        ) : null}
                    </>
                }
                extraRight={
                    <>
                        {
                            //新的客户信息
                            <Form
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 11 }}
                                title="客户信息"
                                layout="horizontal"
                                className="new-costumer"
                            >
                                <h3 className="title">客户信息</h3>
                                <Form.Item label="客户所有人: ">
                                    {staticData?.owner_id?.relatedObj?.name ||
                                        '- -'}
                                </Form.Item>
                                <Form.Item label="合作状态:">
                                    {(() => {
                                        const cooperation_status =
                                            originData?.cooperation_status;
                                        if (_.isNil(cooperation_status)) {
                                            return '- -';
                                        }
                                        const metas =
                                            defaultCustomerListStore
                                                ?.defaultMeta?.objectMeta || [];
                                        const metaItem = metas.find(
                                            (item: any) =>
                                                item.fieldName ===
                                                'cooperation_status',
                                        );
                                        if (_.isNil(metaItem)) {
                                            return '- -';
                                        }
                                        const item = (
                                            metaItem?.constraint
                                                ?.constraintItemList || []
                                        ).find(
                                            (item: any) =>
                                                item.constraintValue ===
                                                cooperation_status,
                                        );
                                        return item?.constraintLabel || '- -';
                                    })()}
                                </Form.Item>
                                <Form.Item label="认领时间:">
                                    {originData?.claim_time
                                        ? moment(originData?.claim_time).format(
                                              'YYYY-MM-DD',
                                          )
                                        : '- -'}
                                </Form.Item>
                                <Form.Item label="到期时间:">
                                    {originData?.expire_time
                                        ? (() => {
                                              const moExpireTime = moment(
                                                  originData?.expire_time,
                                              ).startOf('day');
                                              const moNow = moment().startOf(
                                                  'day',
                                              );
                                              const diff = moExpireTime.diff(
                                                  moNow,
                                                  'days',
                                              );

                                              if (diff > 0) {
                                                  return (
                                                      moExpireTime.format(
                                                          'YYYY-MM-DD',
                                                      ) +
                                                      `(剩余${moExpireTime.diff(
                                                          moNow,
                                                          'days',
                                                      )}天)`
                                                  );
                                              }
                                              return (
                                                  <span
                                                      style={{
                                                          color: '#ff001f',
                                                      }}
                                                  >
                                                      {moExpireTime.format(
                                                          'YYYY-MM-DD',
                                                      )}
                                                  </span>
                                              );
                                          })()
                                        : '- -'}
                                </Form.Item>
                                <Form.Item label="回收类型:">
                                    {(() => {
                                        const recycle_type =
                                            staticData?.recycle_type;
                                        if (_.isNil(recycle_type)) {
                                            return '- -';
                                        }
                                        const metas =
                                            defaultCustomerListStore
                                                ?.defaultMeta?.objectMeta || [];
                                        const metaItem = metas.find(
                                            (item: any) =>
                                                item.fieldName ===
                                                'recycle_type',
                                        );
                                        if (_.isNil(metaItem)) {
                                            return '- -';
                                        }
                                        const item = (
                                            metaItem?.constraint
                                                ?.constraintItemList || []
                                        ).find(
                                            (item: any) =>
                                                item.constraintValue ===
                                                recycle_type,
                                        );
                                        return item?.constraintLabel ? (
                                            <Popover
                                                placement="bottomLeft"
                                                content={
                                                    rt2Hover?.[recycle_type]
                                                }
                                            >
                                                <span
                                                    style={{ color: '#ff001f' }}
                                                >
                                                    {item?.constraintLabel}
                                                </span>
                                            </Popover>
                                        ) : (
                                            '- -'
                                        );
                                    })()}
                                </Form.Item>
                                {/* <Form.Item label="团队成员:">
                                    {
                                        //团队成员，在这里进行折叠
                                        isStandard && type === 'mine' && (
                                            <CustomerTeamMember
                                                dataSource={drawerData?.teamMemberData}
                                                userField={drawerData?.userField}
                                                defaultAuthData={drawerData?.defaultAuthData}
                                                createCustomId={drawerData?.staticData?.id}
                                                reloadAll={reload}
                                                ref={childRef}
                                            />
                                        )}
                                </Form.Item> */}
                            </Form>
                        }
                        {//团队成员，在这里进行折叠
                        isStandard && type === 'mine' && (
                            <CustomerTeamMember
                                dataSource={drawerData?.teamMemberData}
                                userField={drawerData?.userField}
                                defaultAuthData={drawerData?.defaultAuthData}
                                createCustomId={drawerData?.staticData?.id}
                                reloadAll={reload}
                            />
                        )}
                        {
                            //不懂是啥
                        }
                        <CustomerActivityFollowJobCreate
                            customers={[originData]}
                            followLog={createdFollowLog}
                            trigger={customerActivityFollowJobCreateTrigger}
                            onSuccess={() => {}}
                        />
                        {
                            //添加活动记录的抽屉
                        }
                        <AddFollowLog
                            entityType={'customer'}
                            isOpenPopover={false}
                            cid={cid as number}
                            id={cid}
                            drawerVis={followActionModalVis}
                            setDrawerVis={setFollowActionModalVis}
                            initMark={followActionInitMark}
                            isNew={originData.is_new}
                            onCreated={data => {
                                // 这里检查这条活动数据，满足一定条件就弹任务窗口
                                if (data.isCreateJob === 1) {
                                    setCreatedFollowLog(data);
                                    setCustomerActivityFollowJobCreateTrigger(
                                        1 +
                                            customerActivityFollowJobCreateTrigger,
                                    );
                                }
                            }}
                        />

                        {/* 客户记录卡片 */}
                        {/* <CustomerInfoCard
                            isCustomerInfoEditAble={isCustomerInfoEditAble}
                            id={cid}
                            customerId={originData.id}
                            entityType={'customer'}
                            isNewCustomer={isNewCustomer}
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            actionLogConfig={actionLogConfig}
                        /> */}

                        {/* 活动记录的卡片 */}
                        <CustomerActionLogNew
                            isNewCustomer={isNewCustomer}
                            entityType={'customer'}
                            id={cid}
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            actionLogConfig={drawerData?.actionLogConfig}
                            drawerVis={drawerVis}
                            permFollow={permFollow}
                        />
                    </>
                }
                // DrawerCardListConfig={cardExampleConfig}
                DrawerTabsConfig={tabsExampleConfig}
            />
            <CustomerLabelsModal
                visible={visibleLabelsModal}
                customerIds={[cid]}
                initialValues={originData?.label_list}
                onClose={() => {
                    setVisibleLabelsModal(false);
                }}
                onSubmit={() => {
                    setVisibleLabelsModal(false);
                    tryRefresh(['customer']);
                }}
            />
        </CtxProvider>
    );
};

export default observer(CustomerDrawer);
