import React, { useContext } from 'react';
import { applyCustomConfToCellsSelector, isMultipleCell } from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import { ContactListStore } from '@/pages/sales-follow-up/contact/list/store';
import { runInAction } from 'mobx';
import { DTable } from '@/components/common/table';
import { tryRefresh } from '@/utils';
import { makeObjColClickable } from '@/components/common/form';
import { message } from 'antd';
import { IAddFollowLogPropsFromEventOrProps } from '@/components/common/add-follow-log-new';
import { fetchUserProfile } from '../../../../../utils/helpers';
import voyagerTrack from '@/utils/voyager_track';

const FormDemoTable: React.FC<{
    defaultStore: ContactListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['contacts'])}
                defaultStore={defaultStore}
                sceneCode={'crm_contacts'}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // 自定义cell
                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'contacts_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'LinkContacts';
                            nextCellSelector.inputs.push({
                                queryFetcher: (item: any) => ({
                                    id: item.id,
                                    target: 'customer-drawer',
                                }),
                                url: '/buy/contact/all',
                            });
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'contacts_phone',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'CC';
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'duplicate_flag',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'DuplicateFlag';
                            return nextCellSelector;
                        },
                    );

                    // makeObjColClickable(
                    //     baiscCellsSelector,
                    //     'customer_id',
                    // );

                    return baiscCellsSelector;
                }}
                propsTransformer={props => {
                    props.lockWidth = {
                        duplicate_flag: 50,
                        contacts_name: 70,
                        contacts_duty: 70,
                    };
                    props.needMoreNum = 3;
                    // 自定义table props
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'follow',
                            actionCn: '跟进',
                        },
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                        {
                            actionKey: 'view-profile',
                            actionCn: '查看profile',
                        },
                    ];
                    props.actionsWidth = 260;
                    props.onAction = async (key, data) => {
                        if (key === 'follow') {
                            // TODO:这里需要确保cid的获取
                            const cid = data?.customer_id?.originalValue;
                            if (!cid) {
                                return message.error('无cid');
                            }
                            const followConfig: IAddFollowLogPropsFromEventOrProps = {
                                id: cid,
                                cid,
                                entityType: 'contacts',
                                targetFixed: true,
                                addFollowData: {
                                    contactsId: data.id,
                                },
                            };
                            customerListStore.defaultEE.emit(
                                'save-action-log-show',
                                followConfig,
                            );
                        } else if (key === 'view-profile') {
                            const profileUrl = await fetchUserProfile(
                                data.contacts_phone,
                            );
                            if (profileUrl) {
                                voyagerTrack('crm_view_contacts_profile', {
                                    sense: 1,
                                });
                                window.open(profileUrl, '_blank');
                            }
                        } else if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // if (key.indexOf('/customer_id') !== -1) {
                            //     window.open('/buy/custom/my-custom?' + JSON.stringify({
                            //         id: data.customer_id?.originalValue,
                            //         target: 'customer_darwer'
                            //     }), '__blank')
                            // }
                        } else if (key === 'see-dupli-detail') {
                            defaultStore.setAction('see-dupli-detail');
                            defaultStore.setDupDetailContacts(data);
                        }
                    };
                    props.adjustHeight = false;
                    props.closeRowSelection = true;
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
