/**
 * voyager 打点
 */

import Voyager from './voyager/index';

let voyagerInstance = null;

function track(trackName, trackParams) {
    if (!voyagerInstance) {
        voyagerInstance = new Voyager({});
    }

    return voyagerInstance.trackEvent(trackName, '', trackParams);
}

export { track as default };
